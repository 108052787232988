import React from "react";
import {
  Card,
  CardHeader,
  CardPreview,
  Divider,
  Title1,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { ActivityTable } from "../../../shared/components/Activity/ActivityTable";
import ActivityFilters from "../../../shared/components/Activity/ActivityFilters";
import { containerBreakpoints } from "../../../utils/breakpoints";
import { useDashboardContext } from "../context/DashboardContext";

interface ActivityProps {}

const useStyles = makeStyles({
  root: {},
  cardHeader: {
    display: "flex",
    "& > div": {
      display: "flex",
      flexGrow: 1,
    },
  },
  cardHeaderContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    ...containerBreakpoints.xs({
      flexWrap: "wrap",
      flexDirection: "column",
      ...shorthands.gap(tokens.spacingHorizontalM),
    }),
  },
  cardPreview: {
    ...shorthands.padding(tokens.spacingHorizontalS),
  },
});

export const Activity: React.FC<ActivityProps> = () => {
  const styles = useStyles();

  const { activityFilterPeriod, setActivityFilterPeriod } =
    useDashboardContext();

  return (
    <Card className={styles.root} data-testid="dashboard-activity">
      <CardHeader
        className={styles.cardHeader}
        header={
          <div className={styles.cardHeaderContent}>
            <Title1>Activity</Title1>
            <ActivityFilters
              filters={{ byPeriod: true, byScope: false }}
              setActivityFilterPeriod={setActivityFilterPeriod}
            />
          </div>
        }
      ></CardHeader>
      <Divider />

      <CardPreview className={styles.cardPreview}>
        <ActivityTable activityFilterPeriod={activityFilterPeriod} />
      </CardPreview>
    </Card>
  );
};
