import { useId, useToastController } from "@fluentui/react-components";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { UserSettings } from "../api/UserApi";
import { DEFAULT_USER_CONFIGURATION } from "../tabs/Settings/components/Config/constants";

const StoreContext = createContext<{
  isApiConnectionAvailable: boolean | undefined;
  setIsApiConnectionAvailable: (value: boolean) => void;

  isTenantConfigured: boolean | undefined;
  setIsTenantConfigured: (value: boolean) => void;

  toasterId: string;
  dispatchToast: (
    content: React.ReactNode,
    options?: any | undefined // TODO: Apply correct type: DispatchToastOptions
  ) => void;

  userSettings: UserSettings;
  setUserSettings: (value: UserSettings) => void;
}>({
  isApiConnectionAvailable: true,
  setIsApiConnectionAvailable: () => {},

  isTenantConfigured: undefined,
  setIsTenantConfigured: () => {},

  toasterId: "",
  dispatchToast: () => {},

  userSettings: DEFAULT_USER_CONFIGURATION,
  setUserSettings: () => {},
});

interface ContextProviderProps extends PropsWithChildren<{}> {}

export const StoreProvider: FC<ContextProviderProps> = ({ children }) => {
  const [isApiConnectionAvailable, setIsApiConnectionAvailable] =
    useState<boolean>(true);
  const [isTenantConfigured, setIsTenantConfigured] = useState<boolean>();
  const [userSettings, setUserSettings] = useState<UserSettings>(
    DEFAULT_USER_CONFIGURATION
  );

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  return (
    <StoreContext.Provider
      value={{
        isApiConnectionAvailable,
        setIsApiConnectionAvailable,

        isTenantConfigured,
        setIsTenantConfigured,

        toasterId,
        dispatchToast,

        userSettings,
        setUserSettings,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export const useStoreContext = () => {
  const context = useContext(StoreContext);

  if (!context) {
    throw new Error("useStoreContext must be used within a StoreProvider");
  }

  return context;
};
