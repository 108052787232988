const config = {
  env: process.env.REACT_APP_ENV ?? "dev",
  teamsAppId:
    process.env.REACT_APP_TEAMS_APP_ID ?? "PROVIDE_REACT_APP_TEAMS_APP_ID",
  siteUrl: process.env.REACT_APP_SITE_URL ?? "PROVIDE_SITE_URL",
  appInsightsConnectionString:
    process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING ??
    "PROVIDE_REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING",
  appInsightsInstrumentationKey:
    process.env.REACT_APP_APPLICATIONINSIGHTS_INSTRUMENTATION_KEY ??
    "PROVIDE_REACT_APP_APPLICATIONINSIGHTS_INSTRUMENTATION_KEY",
  googleAnalyticsMeasurementId:
    process.env.REACT_APP_GOOGLE_ANALYTICS_ID ??
    "PROVIDE_REACT_APP_GOOGLE_ANALYTICS_ID",
};

export default config;
