import React from "react";
import {
  Body1,
  Caption1,
  Card,
  CardHeader,
  CardPreview,
  Text,
  Subtitle2,
  Tooltip,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { UserAchievement } from "../../../api/AchievementApi";
import {
  getAchievementImage,
  getAchievementStatus,
  getAchievementProgress,
  getAchievementType,
} from "../utils/helpers";
import { containerBreakpoints } from "../../../utils/breakpoints";

export interface AchievementCardProps {
  userAchievement: UserAchievement;
}

const useStyles = makeStyles({
  card: {
    width: "280px",
    maxWidth: "300px",
    height: "fit-content",
    flexGrow: 1,

    ...containerBreakpoints.xs({
      maxWidth: "100%",
    }),

    "&[data-status='bronze']": {
      backgroundColor: tokens.colorStatusWarningBackground2,
    },
    "&[data-status='silver']": {
      backgroundColor: tokens.colorPalettePlatinumBackground2,
    },
    "&[data-status='gold']": {
      backgroundColor: tokens.colorPaletteGoldBackground2,
    },
  },

  cardImage: {
    width: "64px",
    height: "64px",
    ...shorthands.padding(tokens.spacingHorizontalXXS),
  },

  cardContent: {
    flexShrink: "1 !important",
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  text: {
    ...shorthands.margin(0),
  },

  tooltipTitle: {
    display: "flex",
    flexDirection: "column",
  },
});

export const AchievementCard: React.FC<AchievementCardProps> = ({
  userAchievement,
}) => {
  const styles = useStyles();

  return (
    <Tooltip
      content={
        <div className={styles.tooltipTitle}>
          <Subtitle2>
            {getAchievementType(userAchievement.achievement.type)}
          </Subtitle2>
          <Body1>{userAchievement.achievement.description}</Body1>
        </div>
      }
      relationship="description"
      withArrow={true}
    >
      <Card
        className={styles.card}
        orientation="horizontal"
        data-status={userAchievement.status}
        data-testid="achievement-card"
      >
        <CardPreview className={styles.cardImage}>
          <img
            className={styles.cardImage}
            src={getAchievementImage(userAchievement)}
            alt={userAchievement.achievement.name}
            data-testid="achievement-card--image"
          />
        </CardPreview>

        <CardHeader
          className={styles.cardContent}
          header={
            <Text weight="semibold" data-testid="achievement-card--title">
              {userAchievement.achievement.name}
            </Text>
          }
          description={
            <Caption1
              className={styles.caption}
              data-testid="achievement-card--status"
            >
              {getAchievementStatus(userAchievement)}
            </Caption1>
          }
          action={
            <span data-testid="achievement-card--progress">
              {getAchievementProgress(userAchievement)}
            </span>
          }
        />
      </Card>
    </Tooltip>
  );
};
