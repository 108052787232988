import { Api } from "./Api";

export type UserFeedback = {
  id: number;
  userId: string;
  email: string;
  message: string;
  endorsement: boolean;
  score: number;
  type: UserFeedbackType;
  status: UserFeedbackStatus;
};

// Note: Keep in sync with FE
export enum UserFeedbackType {
  Manual = "manual",
  Level3 = "level-3",
}

// Note: Keep in sync with FE
export enum UserFeedbackStatus {
  Pending = "pending",
  Dismissed = "dismissed",
  PartiallyCollected = "partially-collected",
  Collected = "collected",
}

interface CreateUserFeedbackRequest {
  providerUserId: string | null;
  email: string | null;
  message: string;
  endorsement: boolean;
  score: number;
}

interface UpdateUserFeedbackRequest {
  id: number;
  email?: string | null;
  message?: string;
  endorsement?: boolean;
  score?: number;
  status: UserFeedbackStatus;
}

export class FeedbackApi {
  public static API_ENDPOINT = `feedback`;

  public static async createUserFeedback(
    userFeedback: CreateUserFeedbackRequest
  ): Promise<UserFeedback | null> {
    const url = `${FeedbackApi.API_ENDPOINT}`;
    const response = await Api.post(url, userFeedback);

    return response;
  }

  public static async updateUserFeedback(
    userFeedback: UpdateUserFeedbackRequest
  ): Promise<UserFeedback | null> {
    const url = `${FeedbackApi.API_ENDPOINT}/${userFeedback.id}`;
    const response = await Api.patch(url, userFeedback);

    return response;
  }
}
