import { Api } from "./Api";

// Note: Keep in sync with the server
export enum AchievementType {
  Generic = "generic",
  PizzaMastery = "pizza-mastery",
  IngredientsCollector = "ingredients-collector",
  Counter = "counter",
  Other = "other", // a.k.a Easter egg
}

export enum AchievementGenericType {
  FirstEndorser = "first-endorser",
  FirstEndorsement = "first-endorsement",
}

export enum AchievementCounterType {
  EndorsementsSent = "endorsements-sent",
  EndorsementsReceived = "endorsements-received",
  Recipes = "recipes",
}

export enum AchievementOtherType {
  PineapplePleasure = "pineapple-pleasure",
  ChangelogCheerleader = "changelog-cheerleader",
}

export enum UserAchievementStatus {
  NotStarted = "not-started",
  InProgress = "in-progress",
  Completed = "completed",
  Bronze = "bronze",
  Silver = "silver",
  Gold = "gold",
}

export type Achievement = {
  id: number;
  type: AchievementType;
  slug: string;
  name: string;
  description: string;
  configuration: AchievementConfiguration;
};

export interface AchievementConfiguration {
  image: string;
  states?: {
    [key: string]: { progress: number; step?: number };
  };
}

export type UserAchievement = {
  id: number;
  userId: number;
  achievementId: number;
  achievement: Achievement;
  status: UserAchievementStatus;
  progress: number;
  createdAt: string;
  updatedAt: string;
};

export class AchievementApi {
  public static API_ENDPOINT = `achievements`;

  public static async getUserAchievements(): Promise<UserAchievement[] | null> {
    const url = `${AchievementApi.API_ENDPOINT}/`;
    const response = await Api.get(url);

    return response;
  }
}
