import React, { useEffect } from "react";
import {
  Body1,
  Body1Stronger,
  Caption1,
  Divider,
  Subtitle2,
  Title1,
  Title2,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import { containerBreakpoints } from "../../utils/breakpoints";
import Setup from "../Setup";
import { useStoreContext } from "../../context/StoreContext";
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics";
import { UserProfileDialogProvider } from "../../shared/components/UserProfileDialog/context";
import { UserProfileDialog } from "../../shared/components/UserProfileDialog/UserProfileDialog";
import { UserFeedbackSection } from "../DashBoard/components/UserFeedbackSection";
import { UserDailyRecipe } from "../DashBoard/components/UserDailyRecipe";
import { Activity } from "./components/Activity";
import { OverviewProvider } from "./context/OverviewContext";

const useStyles = makeStyles({
  root: {
    containerType: "inline-size",
    containerName: "page-container",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalL),
  },
  title: {
    ...containerBreakpoints.xs({
      textAlign: "center",
    }),
  },
  layout: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    ...shorthands.margin(tokens.spacingVerticalL, 0),
    ...shorthands.gap(tokens.spacingVerticalL),
  },
  guide: {
    display: "flex",

    ...shorthands.gap(tokens.spacingVerticalL),

    ...containerBreakpoints.xs({
      flexDirection: "column-reverse",
      alignItems: "center",
    }),
  },
  guideInfo: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 5,

    ...containerBreakpoints.xs({
      flexGrow: 1,
    }),
  },
  guideImage: {
    display: "flex",
    maxWidth: "300px",
    ...shorthands.margin("-20px", 0, 0, 0),

    "& img": {
      width: "100%",
    },

    ...containerBreakpoints.xs({
      flexGrow: 1,
    }),
  },
  infoStep: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalXS),
  },
  highlight: {
    display: "inline-block",
    backgroundColor: tokens.colorNeutralBackground1,
    fontFamily: tokens.fontFamilyMonospace,
    fontWeight: tokens.fontWeightSemibold,

    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    ...shorthands.padding(tokens.spacingHorizontalXXS),
  },
  infoExample: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: tokens.colorNeutralBackground2,

    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    ...shorthands.margin(tokens.spacingVerticalL, 0),
  },
  navigation: {
    display: "flex",

    ...shorthands.gap(tokens.spacingHorizontalS),

    ...containerBreakpoints.s({
      flexDirection: "column",
    }),
  },
  quickLink: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: tokens.colorNeutralBackground2,
    fontSize: tokens.fontSizeBase500,

    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
  },
  iconDashboard: {
    position: "relative",
    ...shorthands.margin(0, 0, "-2px", 0),
  },
  iconLeaderboard: {
    position: "relative",
    ...shorthands.margin(0, 0, "-2px", 0),
  },
  iconAchievements: {
    position: "relative",
    ...shorthands.margin(0, 0, "-3px", 0),
  },
  dashboard: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    maxWidth: "100%",
    ...shorthands.gap(tokens.spacingVerticalL),

    ...containerBreakpoints.s({
      flexBasis: "100%",
      width: "100%",
      height: "100%",
    }),
    ...containerBreakpoints.xs({
      maxWidth: "100%",
    }),
  },
});

export const Overview = () => {
  const styles = useStyles();

  const { isTenantConfigured } = useStoreContext();
  const { sendPageView } = useGoogleAnalytics();

  useEffect(() => {
    sendPageView({
      page: "/overview",
      title: "Overview",
    });
  }, []);

  if (!isTenantConfigured) {
    return <Setup />;
  }

  return (
    <OverviewProvider>
      <UserProfileDialogProvider>
        <div className={styles.root} data-testid="overview-page">
          <div className={styles.header}>
            <Title1 block className={styles.title}>
              Discover the joy of sharing pizza 🍕🎉
            </Title1>
            <Caption1>
              Welcome to PizzaTime, your ultimate tool for spreading joy and
              appreciation in the workplace! Our app allows you to share virtual
              pizzas with your colleagues, making every workday a little more
              fun and a lot more rewarding. Whether you’re celebrating
              achievements, recognizing hard work, or just sending a little
              cheer, PizzaTime is here to help you create a positive and
              engaging work environment.
            </Caption1>
            <Divider />
          </div>

          <div className={styles.layout}>
            <div className={styles.dashboard}>
              <UserFeedbackSection />
              <UserDailyRecipe />
              <Activity />
            </div>
          </div>

          <div className={styles.layout}>
            <Title2>How to endorse a colleague?</Title2>
            <Body1>
              Endorsing a colleague with PizzaTime is simple and rewarding.
              Follow these easy steps to spread the pizza love:
            </Body1>
            <div className={styles.guide}>
              <div className={styles.guideInfo}>
                <div className={styles.infoStep}>
                  <Body1Stronger>1. Mention the Bot:</Body1Stronger>
                  <Body1>
                    Type <span className={styles.highlight}>@PizzaTime</span> to
                    mention the bot in your message. This lets the bot know
                    you’re about to send an endorsement.{" "}
                  </Body1>
                </div>
                <div className={styles.infoStep}>
                  <Body1Stronger>2. Tag Your Colleague:</Body1Stronger>
                  <Body1>
                    Mention your colleague's{" "}
                    <span className={styles.highlight}>@Name</span> to tag them
                    in the endorsement.
                  </Body1>
                </div>
                <div className={styles.infoStep}>
                  <Body1Stronger>3. Write a Heartfelt Message:</Body1Stronger>
                  <Body1>
                    Share why you’re endorsing your colleague. Let them know how
                    much you appreciate their help and hard work.
                  </Body1>
                  <Body1>
                    And don't forget to add virtual pizza slice to your message
                    🍕
                  </Body1>
                </div>
              </div>

              <div className={styles.guideImage}>
                <img src="/images/welcome.png" alt="Welcome to PizzaTime" />
              </div>
            </div>
            <div className={styles.infoExample}>
              <Subtitle2>Just like that:</Subtitle2>
              <Body1>
                Thank you <span className={styles.highlight}>@Adele Vance</span>{" "}
                - Your help means the world to me! Let's celebrate with a
                virtual treat, it's{" "}
                <span className={styles.highlight}>@PizzaTime!</span> 🍕
              </Body1>
            </div>
          </div>
        </div>
        <UserProfileDialog />
      </UserProfileDialogProvider>
    </OverviewProvider>
  );
};
