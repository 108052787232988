import {
  Text,
  Card,
  CardHeader,
  CardPreview,
  makeStyles,
  shorthands,
  Button,
  Toast,
  ToastTitle,
  ToastBody,
  ToastFooter,
  Tooltip,
} from "@fluentui/react-components";
import React, { useCallback, useEffect, useState } from "react";
import { containerBreakpoints } from "../../../utils/breakpoints";
import {
  DismissRegular,
  EmojiSadFilled,
  EmojiSadRegular,
} from "@fluentui/react-icons";
import { InlineFeedback } from "./UserFeedback/InlineFeedback";
import { useUserProfile } from "../hooks/useUserProfile";
import {
  FeedbackApi,
  UserFeedback,
  UserFeedbackStatus,
  UserFeedbackType,
} from "../../../api/FeedbackApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useStoreContext } from "../../../context/StoreContext";
import { QueryKey } from "../../../constants/api";

interface UserFeedbackProps {}

const useStyles = makeStyles({
  card: {
    alignItems: "normal",
    ...containerBreakpoints.xs({
      flexDirection: "column",
    }),
  },
  cardHeader: {
    flexShrink: "100 !important",
  },
  cardPreview: {
    width: "130px",
    height: "130px",
    ...shorthands.margin(`0 !important`, `0 !important`),
    ...containerBreakpoints.xs({
      display: "flex",
      width: "100%",
      height: "auto",
      aspectRatio: "1 / 1",
    }),
  },
  cardTitle: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardDismiss: {
    marginTop: "-2px",
    marginBottom: "4px",
  },
});

export const UserFeedbackSection: React.FC<UserFeedbackProps> = () => {
  const styles = useStyles();

  const [userFeedback, setUserFeedback] = useState<UserFeedback>();
  const { isSuccessUser, user, teamsUser } = useUserProfile();
  const { dispatchToast } = useStoreContext();

  useEffect(() => {
    if (user?.feedback) {
      const feedback = user.feedback.find(
        (feedback) =>
          (feedback.status === UserFeedbackStatus.Pending ||
            feedback.status === UserFeedbackStatus.PartiallyCollected) &&
          feedback.type === UserFeedbackType.Level3
      );

      if (feedback) {
        setUserFeedback(feedback);
      }
    }
  }, [user]);

  const queryClient = useQueryClient();

  const {
    mutateAsync: sendUserFeedback,
    isError,
    error,
  } = useMutation({
    mutationKey: [QueryKey.UpdateUserFeedback],
    mutationFn: FeedbackApi.updateUserFeedback,
  });

  const handleDismissClick = async () => {
    if (!userFeedback) {
      return;
    }

    try {
      const response = await sendUserFeedback({
        ...userFeedback,
        status: UserFeedbackStatus.Dismissed,
      });

      if (response?.id) {
        // Clear the feedback to avoid showing it again
        setUserFeedback(undefined);
        queryClient.invalidateQueries({
          queryKey: [QueryKey.GetUser, teamsUser?.objectId],
        });

        // TODO: Add link to send feedback
        dispatchToast(
          <Toast>
            <ToastTitle media={<EmojiSadFilled />}>Ohh, really?</ToastTitle>
            <ToastBody>
              No worries, you can still send me some feedback whenever you have
              more time.
            </ToastBody>
            <ToastFooter>Thanks anyway! 🍕</ToastFooter>
          </Toast>,
          {
            timeout: 5000,
            intent: "warning",
          }
        );
      }
    } catch (error) {
      // Note: This is an empty catch block. It is important to handle the error.
      // It will be handled in the useEffect below.
    }
  };

  const handleDismissError = useCallback(() => {
    dispatchToast(
      <Toast>
        <ToastTitle media={<EmojiSadRegular />}>Oops!</ToastTitle>
        <ToastBody>
          Something went wrong while trying to dismiss the feedback.
        </ToastBody>
        <ToastFooter>Please try again later.</ToastFooter>
      </Toast>,
      {
        intent: "error",
      }
    );
  }, [dispatchToast]);

  useEffect(() => {
    if (isError) {
      console.error("Error while dismissing feedback", error);
      handleDismissError();
    }
  }, [isError, error, handleDismissError]);

  if (!isSuccessUser || !userFeedback) {
    return null;
  }

  return (
    <Card
      orientation="horizontal"
      className={styles.card}
      data-testid="dashboard--feedback"
    >
      <CardPreview className={styles.cardPreview}>
        <img
          src="/images/avatars/developer.png"
          alt="The chef behind PizzaTime"
        />
      </CardPreview>

      <CardHeader
        className={styles.cardHeader}
        header={
          <div className={styles.cardTitle}>
            <Text weight="semibold">
              Hey there, I would love to hear your feedback 👨‍🍳
            </Text>

            <Tooltip content="Dismiss feedback request" relationship="label">
              <Button
                appearance="transparent"
                size="small"
                icon={<DismissRegular />}
                title="Dismiss"
                onClick={handleDismissClick}
                className={styles.cardDismiss}
                data-testid="dashboard--feedback-dismiss"
              ></Button>
            </Tooltip>
          </div>
        }
        description={<InlineFeedback userFeedback={userFeedback} />}
      />
    </Card>
  );
};
