import { FC, useEffect } from "react";
import { useTenant } from "../../hooks/useTenant";
import SetupTenant from "./components/SetupTenant";
import SetupTab from "./components/SetupTab";
import { useStoreContext } from "../../context/StoreContext";
import {
  Button,
  Spinner,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { SITE_CONTACT_US_URL } from "../../constants/site";
import { Open16Filled } from "@fluentui/react-icons";
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics";

const LOADING_MESSAGES = [
  "One second please...",
  "Just a moment...",
  "Hold on...",
  "Loading...",
  "Hold tight! ",
  "Hang in there!",
  "Grab a slice of patience while we cook up some digital magic!",
  "Our digital pizza oven is preheating for your enjoyment.",
  "We're kneading some dough to serve you a fresh experience!",
  "We're on a mission to deliver smiles and slices, one pixel at a time.",
  "Patience is a virtue, especially when waiting for a virtual pizza party!",
  "Our tech chefs are sprinkling some digital cheese on your experience.",
  "Keep calm and wait for the loading bar to finish its pizza dance!",
  "Almost there! We promise your digital pizza will be worth the wait.",
  "Just a few more slices of code left to bake in our digital oven!",
];

export const getRandomLoadingMessage = () => {
  return LOADING_MESSAGES[Math.floor(Math.random() * LOADING_MESSAGES.length)];
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "500px",
    alignItems: "center",
    alignSelf: "center",
    ...shorthands.margin(0, "auto"),
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    ...shorthands.gap(tokens.spacingHorizontalM),
  },
});

export interface SetupProps {
  onSuccess?: () => void;
}

const Setup: FC<SetupProps> = ({ onSuccess = () => null }) => {
  const { isLoading, isError, refetchTenant } = useTenant();
  const { isApiConnectionAvailable, isTenantConfigured } = useStoreContext();
  const { sendPageView } = useGoogleAnalytics();
  const styles = useStyles();

  useEffect(() => {
    sendPageView({
      page: "/setup",
      title: "Setup",
    });
  }, []);

  return (
    <div className={styles.root} data-testid="setup-tenant">
      <h1>It's Pizza Time! 🍕</h1>
      {isLoading && (
        <Spinner
          labelPosition="below"
          label={getRandomLoadingMessage()}
          data-testid="setup-tenant-loading"
        />
      )}

      {!isLoading && isTenantConfigured === false && <SetupTenant />}
      {!isLoading && isApiConnectionAvailable === false && isError && (
        <>
          <h2>Oh nooo...</h2>
          <p>
            Sorry for the inconvenience but we have to delay the party for a
            while.. for some reason the oven is overwhelmed and we have problems
            reaching our servers.
          </p>
          <p>
            Please try again later or if you continue to experience the same
            issue feel free to contact us, and let us know what happened.
          </p>
          <p>Thank you for your understanding!</p>
          <div className={styles.actionButtons}>
            <Button onClick={refetchTenant}>Try again</Button>
            <Button
              as="a"
              appearance="subtle"
              href={SITE_CONTACT_US_URL}
              target="_blank"
              rel="noreferrer"
            >
              <Open16Filled /> &nbsp; Report an issue
            </Button>
          </div>
        </>
      )}

      {isTenantConfigured && <SetupTab onSuccess={onSuccess} />}
    </div>
  );
};

export default Setup;
