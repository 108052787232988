import {
  Button as FluentUiButton,
  ButtonProps as FluentUiButtonProps,
  Spinner,
} from "@fluentui/react-components";
import { PropsWithChildren, forwardRef } from "react";

export type ButtonProps = FluentUiButtonProps &
  PropsWithChildren & { isLoading?: boolean };

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ isLoading = false, children, ...props }, ref) => {
    const isDisabled = isLoading || props.disabled;
    const buttonIcon = isLoading ? (
      <Spinner size="tiny" data-testid="button--loading-spinner" />
    ) : (
      props.icon
    );

    return (
      <>
        <FluentUiButton {...props} icon={buttonIcon} disabled={isDisabled}>
          {children}
        </FluentUiButton>
      </>
    );
  }
);

export default Button;
