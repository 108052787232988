import { makeStyles, tokens } from "@fluentui/react-components";
import { useUserProfileDialogContext } from "../UserProfileDialog/context";
import { ActivityTableRow } from "./ActivityTable";

export enum ActivityMessagePlaceholder {
  SenderUserPlaceholder = ":senderUserPlaceholder:",
  TargetUserPlaceholder = ":targetUserPlaceholder:",
}

const useStyles = makeStyles({
  userLink: {
    // fontWeight: "bold",
    cursor: "pointer",
    color: tokens.colorBrandForegroundLink,

    "&:hover": {
      textDecoration: "underline",
      color: tokens.colorBrandForegroundLinkHover,
    },
  },
});

export interface ActivityTableMessageProps {
  item: ActivityTableRow;
}

const ActivityTableMessage: React.FC<ActivityTableMessageProps> = ({
  item,
}) => {
  const styles = useStyles();
  const { setIsUserProfileDialogOpen, setSelectedProviderUserId } =
    useUserProfileDialogContext();

  const { message, activity } = item;
  const { providerUserId, userName, targetProviderUserId, targetUserName } =
    activity;

  const handleOpenUserProfile = (providerUserId: string) => {
    setIsUserProfileDialogOpen(true);
    setSelectedProviderUserId(providerUserId);
  };

  // Split the message at the placeholders
  const placeholderValues = Object.values(ActivityMessagePlaceholder).join("|");
  const regexPattern = new RegExp(`(${placeholderValues})`);
  const parts = message.split(regexPattern);

  return (
    <>
      {parts.map((part, index) => {
        if (part === ActivityMessagePlaceholder.TargetUserPlaceholder) {
          return (
            <span
              key={index}
              onClick={() => handleOpenUserProfile(targetProviderUserId)}
              className={styles.userLink}
            >
              {targetUserName}
            </span>
          );
        } else if (part === ActivityMessagePlaceholder.SenderUserPlaceholder) {
          return (
            <span
              key={index}
              onClick={() => handleOpenUserProfile(providerUserId)}
              className={styles.userLink}
            >
              {userName}
            </span>
          );
        } else {
          return part;
        }
      })}
    </>
  );
};

export default ActivityTableMessage;
