import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { User } from "../../../../../api/UserApi";

const AdminContext = createContext<{
  isEditUserDialogOpen: boolean;
  setIsEditUserDialogOpen: (value: boolean) => void;

  editUser: User | null | undefined;
  setEditUser: (value: User | null | undefined) => void;
}>({
  isEditUserDialogOpen: false,
  setIsEditUserDialogOpen: () => {},

  editUser: null,
  setEditUser: () => {},
});

interface AdminContextProviderProps extends PropsWithChildren<{}> {}

export const AdminProvider: FC<AdminContextProviderProps> = ({ children }) => {
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] =
    useState<boolean>(false);
  const [editUser, setEditUser] = useState<User | null>();

  return (
    <AdminContext.Provider
      value={{
        isEditUserDialogOpen,
        setIsEditUserDialogOpen,

        editUser,
        setEditUser,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => {
  const context = useContext(AdminContext);

  if (!context) {
    throw new Error("useAdminContext must be used within a AdminProvider");
  }

  return context;
};
