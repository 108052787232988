import React, { useEffect } from "react";
import { Divider, LargeTitle, makeStyles } from "@fluentui/react-components";
import { RecipientsLeaderBoard } from "./components/RecipientsLeaderBoard";
import { EndorsersLeaderBoard } from "./components/EndorsersLeaderBoard";
import Setup from "../Setup";
import { useStoreContext } from "../../context/StoreContext";
import { LeaderBoardProvider } from "./context/LeaderBoardContext";
import GlobalFilters from "./components/GlobalFilters";
import { containerBreakpoints } from "../../utils/breakpoints";
import { UserProfileDialog } from "../../shared/components/UserProfileDialog/UserProfileDialog";
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics";
import { UserProfileDialogProvider } from "../../shared/components/UserProfileDialog/context";

const useStyles = makeStyles({
  root: {
    containerType: "inline-size",
    containerName: "page-container",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ...containerBreakpoints.xs({
      flexWrap: "wrap",
    }),
  },
  headerTitle: {
    ...containerBreakpoints.xs({
      width: "100%",
      justifyContent: "center",
      textAlign: "center",
    }),
  },
});

export const LeaderBoard = () => {
  const { isTenantConfigured } = useStoreContext();
  const { sendPageView } = useGoogleAnalytics();

  const styles = useStyles();

  useEffect(() => {
    sendPageView({
      page: "/leaderboard",
      title: "Leaderboard",
    });
  }, []);

  if (!isTenantConfigured) {
    return <Setup />;
  }

  return (
    <LeaderBoardProvider>
      <UserProfileDialogProvider>
        <div className={styles.root} data-testid="leaderboard-page">
          <div className={styles.header}>
            <LargeTitle block className={styles.headerTitle}>
              Leaderboard
            </LargeTitle>
            <GlobalFilters />
          </div>
          <Divider />

          <RecipientsLeaderBoard />
          <EndorsersLeaderBoard />
          <UserProfileDialog />
        </div>
      </UserProfileDialogProvider>
    </LeaderBoardProvider>
  );
};
