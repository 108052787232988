import { useQuery } from "@tanstack/react-query";
import { FeatureFlag, FeatureFlagApi } from "../api/FeatureFlagApi";
import { QueryKey } from "../constants/api";

interface FeatureFlagHook {
  value: boolean;
}

export const useFeatureFlag = (flagName: FeatureFlag): FeatureFlagHook => {
  const { data: response } = useQuery({
    queryKey: [QueryKey.GetFeatureFlag, flagName],
    queryFn: () => FeatureFlagApi.getFeatureFlag(flagName),
  });

  return {
    value: response?.value ?? false,
  };
};
