export enum TabTeamType {
  Tenant = "tenant",
  Team = "team",
}

export enum TabChatType {
  GroupChat = "group",
  ChannelChat = "channel",
  MeetingChat = "meeting",
}
