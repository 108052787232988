import React, { useEffect, useState } from "react";
import {
  Button,
  MessageBarActions,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";
import { AchievementApi, UserAchievement } from "../../../api/AchievementApi";
import { Alert } from "../../../components/Alert";
import { ArrowCounterclockwise24Regular } from "@fluentui/react-icons";
import { AchievementCard } from "./AchievementCard";
import { useAchievementsContext } from "../context/AchievementsContext";
import AchievementSkeleton from "../../../components/Skeletons/AchievementSkeleton";
import { QueryKey } from "../../../constants/api";

interface UserAchievementsProps {}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    ...shorthands.gap(tokens.spacingHorizontalL),
  },
});

export const UserAchievements: React.FC<UserAchievementsProps> = () => {
  const styles = useStyles();
  const [achievements, setAchievements] = useState<UserAchievement[]>([]);
  const { type } = useAchievementsContext();

  const { isLoading, isError, isSuccess, data, refetch } = useQuery({
    queryKey: [QueryKey.GetUserAchievements],
    queryFn: () => AchievementApi.getUserAchievements(),
  });

  useEffect(() => {
    if (data) {
      const filteredAchievements = data.filter(
        (achievement: UserAchievement) => {
          if (type) {
            return achievement.achievement.type === type;
          }

          return true;
        }
      );

      setAchievements(filteredAchievements);
    }
  }, [data, type]);

  return (
    <div className={styles.root}>
      {isLoading && (
        <div data-testid="achievements-skeleton-loader" className={styles.root}>
          <AchievementSkeleton />
          <AchievementSkeleton />
          <AchievementSkeleton />
        </div>
      )}

      {isError && (
        <Alert
          intent="error"
          actions={
            <MessageBarActions
              containerAction={
                <Button
                  appearance="transparent"
                  icon={<ArrowCounterclockwise24Regular />}
                  onClick={() => refetch()}
                  data-testid="achievements-error-retry-button"
                />
              }
            />
          }
        />
      )}

      {isSuccess && achievements.length === 0 && (
        <Alert
          intent="warning"
          title="Unlock a world of achievements!"
          message="Your profile will soon showcase badges and medals earned for your contributions and accomplishments. Keep sending pizzas, leveling up, and mastering new recipes to collect them all!"
        />
      )}

      {isSuccess &&
        achievements.length > 0 &&
        achievements.map((achievement) => (
          <AchievementCard key={achievement.id} userAchievement={achievement} />
        ))}
    </div>
  );
};
