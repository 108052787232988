import { ConfigurationDateFormat, ConfigurationTimeFormat } from "../constants";
import { formatDate, formatTime } from "../../../../../utils/helpers";

export const previewDateFormat = (format: string): string => {
  const date = new Date(Date.now());

  let label: string = format;
  let example: string = formatDate(date, format);

  if (format === ConfigurationDateFormat.Default) {
    label = "Default, your locale";
  }

  return `${example} (${label})`;
};

export const previewTimeFormat = (format: string): string => {
  const date = new Date(Date.now());

  let label: string = format;
  let example: string = formatTime(date, format);

  if (format === ConfigurationTimeFormat.Default) {
    label = "Default, your locale";
  }

  return `${example} (${label})`;
};
