import { Tooltip } from "@fluentui/react-components";
import { useUserProfile } from "../../hooks/useUserProfile";

interface DailyRecipeImageProps {}

export const DEFAULT_RECIPE_ALT = "Daily Recipe";

export const DEFAULT_RECIPE_IMAGE = "/images/recipes/default.png";
const PATTERN_RECIPE_IMAGE = "/images/recipes/:slug/:slug-:quantity.png";

// We want to show a different message for each quantity, to stimulate the user to share more
const DEFAULT_TOOLTIP_MESSAGE = "Share the joy!";
const TOOLTIP_MESSAGES: Record<number, string[]> = {
  0: [
    "Pizza mission complete!",
    "Pizza joy complete!",
    "Victory! Joy shared. Delicious!",
  ],
  1: [
    "Final slice — share it!",
    "Final slice! Share?",
    "Last slice! Share the deliciousness.",
  ],
  2: [
    "Share the joy!",
    "Two more! Go on!",
    "Double joy! Keep sharing the delicious bites.",
  ],
  3: [
    "Trio of joy!",
    "Three to share!",
    "Trio delight! Spread smiles with the delicious pizza.",
  ],
  4: [
    "Fantastic four!",
    "Fabulous four!",
    "Fantastic four! Keep sharing the delicious goodness.",
  ],
  5: [
    "High-five for pizza!",
    "High-five pizzas!",
    "High-five for pizza vibes! Delicious joy awaits.",
  ],
  6: [
    "Half a dozen joy!",
    "Half a dozen pizzas!",
    "Half a dozen pizzas! Keep sharing the delicious delight.",
  ],
};

export const DailyRecipeImage: React.FC<DailyRecipeImageProps> = () => {
  const { isSuccessUser, user } = useUserProfile();

  const getRecipeImage = (): string => {
    if (!isSuccessUser) {
      return DEFAULT_RECIPE_IMAGE;
    }

    const slug = user?.dailyRecipe?.recipe.slug ?? "";
    const quantity = user?.dailyRecipe?.quantity.toString() ?? "";

    if (quantity === "0") {
      return DEFAULT_RECIPE_IMAGE;
    }

    return PATTERN_RECIPE_IMAGE.replaceAll(":slug", slug).replaceAll(
      ":quantity",
      quantity
    );
  };

  const getRecipeAlt = (): string => {
    return user?.dailyRecipe?.recipe.name ?? DEFAULT_RECIPE_ALT;
  };

  const getRecipeTooltipMessage = (): string => {
    if (!isSuccessUser) {
      return DEFAULT_TOOLTIP_MESSAGE;
    }

    // Pick a random message from the list of messages for the current quantity
    const quantity = user?.dailyRecipe?.quantity ?? 0;
    const messages = TOOLTIP_MESSAGES[quantity] ?? [];
    const message = messages[Math.floor(Math.random() * messages.length)];

    return `${quantity} slices left: ${message}`;
  };

  return (
    <Tooltip
      content={getRecipeTooltipMessage()}
      withArrow={true}
      relationship="description"
    >
      <img
        src={getRecipeImage()}
        alt={getRecipeAlt()}
        data-testid="dashboard-daily-recipe-image"
      />
    </Tooltip>
  );
};
