import * as yup from "yup";
import { TabChatType, TabTeamType } from "../types";

export const SetupChatFormValidationSchema = yup
  .object()
  .shape({
    providerTenantId: yup.string().required(),
    providerChatId: yup.string().required(),
    providerChatType: yup
      .string()
      .required()
      .oneOf(Object.values(TabChatType), "Invalid chat type"),
    assignChatTo: yup
      .string()
      .required()
      .oneOf(Object.values(TabTeamType), "Invalid assignment type"),
    teamId: yup.string().when("assignChatTo", {
      is: TabTeamType.Team,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    teamName: yup.string().when("teamId", {
      is: ":create-new-team:",
      then: (schema) =>
        schema
          .required("Team Name is required in order to create a new one")
          .min(3, "It should be minimum 3 characters long")
          .max(25, "It should be maximum 25 characters long"),
      otherwise: (schema) => schema.notRequired(),
    }),
  })
  .required();
