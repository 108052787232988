import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { LeaderBoardTableRow } from "../components/LeaderBoardTable";

const LeaderBoardContext = createContext<{
  teamId: number;
  setTeamId: (value: number) => void;
}>({
  teamId: 0,
  setTeamId: () => {},
});

interface LaderBoardContextProviderProps extends PropsWithChildren<{}> {}

export const LeaderBoardProvider: FC<LaderBoardContextProviderProps> = ({
  children,
}) => {
  const [teamId, setTeamId] = useState<number>(0);

  return (
    <LeaderBoardContext.Provider
      value={{
        teamId,
        setTeamId,
      }}
    >
      {children}
    </LeaderBoardContext.Provider>
  );
};

export const useLeaderBoardContext = () => {
  const context = useContext(LeaderBoardContext);

  if (!context) {
    throw new Error(
      "useLeaderBoardContext must be used within a LeaderBoardProvider"
    );
  }

  return context;
};
