import {
  Button,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  makeStyles,
  tokens,
  useOverflowMenu,
} from "@fluentui/react-components";
import {
  bundleIcon,
  MoreHorizontalFilled,
  MoreHorizontalRegular,
} from "@fluentui/react-icons";
import { OverflowMenuItem } from "./components/OverflowMenuItem";

export type TabConfig = {
  id: string;
  name: string;
  icon?: React.ReactElement;
};

const useOverflowMenuStyles = makeStyles({
  menu: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  menuButton: {
    alignSelf: "center",
  },
});

export interface OverflowMenuProps {
  tabs: TabConfig[];
  onTabSelect?: (tabId: string) => void;
}

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);

/**
 * A menu for selecting tabs that have overflowed and are not visible.
 */
export const OverflowMenu = (props: OverflowMenuProps) => {
  const { tabs, onTabSelect } = props;
  const { ref, isOverflowing, overflowCount } =
    useOverflowMenu<HTMLButtonElement>();

  const styles = useOverflowMenuStyles();

  const onItemClick = (tabId: string) => {
    onTabSelect?.(tabId);
  };

  if (!isOverflowing) {
    return null;
  }

  return (
    <Menu hasIcons>
      <MenuTrigger disableButtonEnhancement>
        <Button
          appearance="transparent"
          className={styles.menuButton}
          ref={ref}
          icon={<MoreHorizontal />}
          aria-label={`${overflowCount} more tabs`}
          role="tab"
        />
      </MenuTrigger>
      <MenuPopover>
        <MenuList className={styles.menu}>
          {tabs.map((tab) => (
            <OverflowMenuItem
              key={tab.id}
              tab={tab}
              onClick={() => onItemClick(tab.id)}
            />
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
