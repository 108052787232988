import React from "react";
import {
  Skeleton,
  SkeletonItem,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import type { SkeletonProps } from "@fluentui/react-components";

const useStyles = makeStyles({
  invertedWrapper: {
    width: "250px",
    backgroundColor: tokens.colorNeutralBackground1,
    flexDirection: "column",
  },
  firstRow: {
    alignItems: "center",
    display: "flex",
    position: "relative",
    ...shorthands.gap(tokens.spacingVerticalL),
    ...shorthands.padding(tokens.spacingVerticalL),
    gridTemplateColumns: "min-content 80%",
  },
});

const AchievementSkeleton = (props: Partial<SkeletonProps>) => {
  const styles = useStyles();

  return (
    <div className={styles.invertedWrapper}>
      <Skeleton {...props}>
        <div className={styles.firstRow}>
          <SkeletonItem shape="circle" size={24} />
          <SkeletonItem shape="rectangle" size={16} />
        </div>
      </Skeleton>
    </div>
  );
};

export default AchievementSkeleton;
