import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import config from "../constants/config";
import { APP_INSIGHTS_ENVIRONMENTS } from "../constants/global";

interface AppInsightsHook {
  appInsights: ApplicationInsights | undefined;
  sendPageView: (name: string) => void;
  sendTrace: (message: string, severityLevel?: number) => void;
}

export const useAppInsights = (): AppInsightsHook => {
  let appInsights: ApplicationInsights | undefined;

  const { env, appInsightsInstrumentationKey } = config;

  if (
    env &&
    APP_INSIGHTS_ENVIRONMENTS.includes(env) &&
    appInsightsInstrumentationKey
  ) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsInstrumentationKey,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
      },
    });

    appInsights.loadAppInsights();
  }

  const sendPageView = (name: string) => {
    if (appInsights) {
      appInsights.trackPageView({ name });
    }
  };

  const sendTrace = (message: string, severityLevel: number = 1) => {
    if (appInsights) {
      appInsights.trackTrace({ message, severityLevel });
    }
  };

  return { appInsights, sendPageView, sendTrace };
};
