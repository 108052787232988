import { createContext, useContext } from "react";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import { Theme } from "@fluentui/react-components";

interface TeamsChat {
  id: string;
}
interface TeamsChannel {
  id: string;
  displayName: string;
}

interface TeamsMeeting {
  id: string;
}

export enum TeamsContextType {
  Chat = "chat",
  Channel = "channel",
  Meeting = "meeting",
  Personal = "personal",
}

export interface TeamsContext {
  chat: TeamsChat | undefined;
  channel: TeamsChannel | undefined;
  meeting: TeamsMeeting | undefined;
}

export const TeamsFxContext = createContext<{
  theme?: Theme;
  themeString: string;
  teamsUserCredential?: TeamsUserCredential;
  teams: TeamsContext;
}>({
  theme: undefined,
  themeString: "",
  teamsUserCredential: undefined,
  teams: {
    chat: undefined,
    channel: undefined,
    meeting: undefined,
  },
});

export const useTeamsFxContext = () => {
  const context = useContext(TeamsFxContext);

  if (!context) {
    throw new Error("useTeamsFxContext must be used within a TeamsFxProvider");
  }

  return context;
};
