import React, { useEffect } from "react";
import {
  Button,
  MessageBarActions,
  Toast,
  ToastTitle,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { TabPanel } from "../TabPanel";
import AchievementSkeleton from "../../../../components/Skeletons/AchievementSkeleton";
import { Alert } from "../../../../components/Alert";
import { ArrowCounterclockwise24Regular } from "@fluentui/react-icons";
import { useUserProfile } from "../../../DashBoard/hooks/useUserProfile";
import UserConfigurationForm from "./components/UserConfigurationForm";
import { useStoreContext } from "../../../../context/StoreContext";
import { useGoogleAnalytics } from "../../../../hooks/useGoogleAnalytics";

const useStyles = makeStyles({
  root: {
    display: "flex",
    ...shorthands.padding(tokens.spacingVerticalL),
  },
});

export const Config = () => {
  const styles = useStyles();
  const { dispatchToast } = useStoreContext();

  const { sendPageView } = useGoogleAnalytics();

  useEffect(() => {
    sendPageView({
      page: "/settings/config",
      title: "Settings - Config",
    });
  }, []);

  const { isLoadingUser, isSuccessUser, isErrorUser, user, refetchUser } =
    useUserProfile();

  const onUserConfigurationUpdatedSuccessfully = () => {
    dispatchToast(
      <Toast>
        <ToastTitle>Personal Configuration updated successfully!</ToastTitle>
      </Toast>,
      { intent: "success" }
    );
  };

  return (
    <TabPanel title="Personal Configurations">
      <div className={styles.root} data-testid="settings--config">
        {isLoadingUser && (
          <div data-testid="settings--config-loader">
            <AchievementSkeleton />
          </div>
        )}

        {isErrorUser && (
          <Alert
            intent="error"
            actions={
              <MessageBarActions
                containerAction={
                  <Button
                    appearance="transparent"
                    icon={<ArrowCounterclockwise24Regular />}
                    onClick={() => refetchUser()}
                    data-testid="settings--config-retry"
                  />
                }
              />
            }
          />
        )}

        {isSuccessUser && user && (
          <UserConfigurationForm
            user={user}
            onSuccess={onUserConfigurationUpdatedSuccessfully}
          />
        )}
      </div>
    </TabPanel>
  );
};
