import {
  Text,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useUserProfile } from "../../hooks/useUserProfile";
import { DailyRecipeQuantityBadge } from "./DailyRecipeQuantityBadge";
import { containerBreakpoints } from "../../../../utils/breakpoints";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flexGrow: 1,
    ...containerBreakpoints.xs({
      justifyContent: "center",
      width: "100%",
      ...shorthands.margin(0, 0, tokens.spacingVerticalM, 0),
      ...shorthands.gap(tokens.spacingHorizontalXS),
    }),
  },
  label: {
    ...containerBreakpoints.xs({
      width: "100%",
      display: "inline-block",
      textAlign: "center",
      fontSize: tokens.fontSizeBase500,
    }),
  },
  delimiter: {
    ...shorthands.margin(0, tokens.spacingHorizontalS, 0, 0),
    ...containerBreakpoints.xs({
      display: "none",
    }),
  },
  title: {
    flexGrow: 1,
    ...containerBreakpoints.xs({
      width: "100%",
      display: "inline-block",
      textAlign: "center",
    }),
  },
});

interface DailyRecipeInfoProps {}

export const DailyRecipeInfo: React.FC<DailyRecipeInfoProps> = () => {
  const styles = useStyles();
  const { isSuccessUser, user } = useUserProfile();

  // Construct some aliases for the user's daily recipe
  const userRecipeName = user?.dailyRecipe?.recipe.name;
  const userRecipeQuantityRemaining = user?.dailyRecipe?.quantity ?? 0;

  return (
    <Text weight="semibold" className={styles.root}>
      <span className={styles.label}>
        Recipe of the Day<span className={styles.delimiter}>:</span>
      </span>

      {isSuccessUser && (
        <span className={styles.title}>
          {userRecipeName}
          <DailyRecipeQuantityBadge
            quantityRemaining={userRecipeQuantityRemaining}
            totalQuantity={6}
          />
        </span>
      )}
    </Text>
  );
};
