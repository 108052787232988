export class Api {
  public static API_URL = `${process.env.REACT_APP_API_URL}`;

  public static async get(endpoint: string, params: Record<string, any> = {}) {
    const headers = Api.getHeaders();
    const queryString = new URLSearchParams(params);
    const response = await fetch(`${Api.API_URL}/${endpoint}?${queryString}`, {
      headers,
    });
    const responseData = await response.json();

    if (!response.ok) {
      if (responseData.error) {
        throw new Error(JSON.stringify(responseData));
      }

      throw new Error(response.statusText);
    }

    return responseData;
  }

  public static async post<T>(endpoint: string, data: T) {
    const headers = Api.getHeaders();
    const response = await fetch(`${Api.API_URL}/${endpoint}`, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      if (responseData.error) {
        throw new Error(JSON.stringify(responseData));
      }

      throw new Error(response.statusText);
    }

    return responseData;
  }

  public static async patch<T>(endpoint: string, data: T) {
    const headers = Api.getHeaders();
    const response = await fetch(`${Api.API_URL}/${endpoint}`, {
      method: "PATCH",
      headers,
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      if (responseData.error) {
        throw new Error(JSON.stringify(responseData));
      }

      throw new Error(response.statusText);
    }

    return responseData;
  }

  private static getHeaders(): Headers {
    const headers = new Headers();
    const authToken = localStorage.getItem("authToken");

    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${authToken}`);

    return headers;
  }
}
