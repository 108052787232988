import {
  MenuItem,
  MenuItemProps,
  useIsOverflowItemVisible,
} from "@fluentui/react-components";
import { TabConfig } from "..";

export interface OverflowMenuItemProps {
  tab: TabConfig;
  onClick: MenuItemProps["onClick"];
}

/**
 * A menu item for an overflow menu that only displays when the tab is not visible
 */
export const OverflowMenuItem = (props: OverflowMenuItemProps) => {
  const { tab, onClick } = props;
  const isVisible = useIsOverflowItemVisible(tab.id);

  if (isVisible) {
    return null;
  }

  return (
    <MenuItem key={tab.id} icon={tab.icon} onClick={onClick}>
      <div>{tab.name}</div>
    </MenuItem>
  );
};
