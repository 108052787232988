import React from "react";
import {
  Skeleton,
  SkeletonItem,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import type { SkeletonProps } from "@fluentui/react-components";

const useStyles = makeStyles({
  invertedWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  firstRow: {
    alignItems: "center",
    display: "grid",
    paddingBottom: tokens.spacingVerticalL,
    position: "relative",
    ...shorthands.gap(tokens.spacingVerticalL),
    gridTemplateColumns: "min-content 80%",
  },
  secondThirdRow: {
    alignItems: "center",
    display: "grid",
    paddingBottom: tokens.spacingVerticalL,
    position: "relative",
    ...shorthands.gap(tokens.spacingVerticalL),
    gridTemplateColumns: "min-content 20% 20% 15% 15%",
  },
});

// TODO: Rework to make it looks like a table
const TableSkeleton = (props: Partial<SkeletonProps>) => {
  const styles = useStyles();

  return (
    <div className={styles.invertedWrapper} data-testid="table-skeleton-loader">
      <Skeleton {...props}>
        <div className={styles.firstRow}>
          <SkeletonItem shape="circle" size={24} />
          <SkeletonItem shape="rectangle" size={16} />
        </div>
        <div className={styles.secondThirdRow}>
          <SkeletonItem shape="circle" size={24} />
          <SkeletonItem size={16} />
          <SkeletonItem size={16} />
          <SkeletonItem size={16} />
          <SkeletonItem size={16} />
        </div>
        <div className={styles.secondThirdRow}>
          <SkeletonItem shape="square" size={24} />
          <SkeletonItem size={16} />
          <SkeletonItem size={16} />
          <SkeletonItem size={16} />
          <SkeletonItem size={16} />
        </div>
      </Skeleton>
    </div>
  );
};

export default TableSkeleton;
