import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

const AchievementsContext = createContext<{
  type: string;
  setType: (value: string) => void;
}>({
  type: "",
  setType: () => {},
});

interface AchievementsContextProviderProps extends PropsWithChildren<{}> {}

export const AchievementsProvider: FC<AchievementsContextProviderProps> = ({
  children,
}) => {
  const [type, setType] = useState<string>("");

  return (
    <AchievementsContext.Provider
      value={{
        type,
        setType,
      }}
    >
      {children}
    </AchievementsContext.Provider>
  );
};

export const useAchievementsContext = () => {
  const context = useContext(AchievementsContext);

  if (!context) {
    throw new Error(
      "useAchievementsContext must be used within a AchievementsProvider"
    );
  }

  return context;
};
