import {
  Select,
  makeStyles,
  shorthands,
  tokens,
  useId,
} from "@fluentui/react-components";
import { containerBreakpoints } from "../../../utils/breakpoints";
import { endOfToday, startOfMonth, startOfToday, startOfWeek } from "date-fns";
import { useState } from "react";

export enum ActivityPeriod {
  Today = "today",
  Week = "week",
  Month = "month",
  All = "all",
}

export const ActivityPeriodLabels: Record<ActivityPeriod, string> = {
  [ActivityPeriod.Today]: "Today",
  [ActivityPeriod.Week]: "Week",
  [ActivityPeriod.Month]: "Month",
  [ActivityPeriod.All]: "All",
};

export const ActivityPeriodRanges: Record<ActivityPeriod, [Date, Date]> = {
  [ActivityPeriod.Today]: [startOfToday(), endOfToday()],
  [ActivityPeriod.Week]: [
    startOfWeek(new Date(), { weekStartsOn: 1 }), // TODO: Add config for this
    endOfToday(),
  ],
  [ActivityPeriod.Month]: [startOfMonth(new Date()), endOfToday()],
  [ActivityPeriod.All]: [new Date("2023-12-13"), endOfToday()],
};

export enum ActivityScope {
  Personal = "personal",
  Team = "team",
}

export const ActivityScopeLabels: Record<ActivityScope, string> = {
  [ActivityScope.Personal]: "Personal",
  [ActivityScope.Team]: "Team",
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap(tokens.spacingVerticalM),
    ...containerBreakpoints.xs({
      width: "100%",
      justifyContent: "space-between",
    }),
    ...containerBreakpoints.xxs({
      flexDirection: "column",
    }),
  },
  filter: {
    display: "flex",
    alignItems: "center",
    ...shorthands.gap(tokens.spacingVerticalM),
    ...containerBreakpoints.xxs({
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
    }),
  },
});

export interface ActivityFiltersProps {
  filters: {
    byPeriod: boolean;
    byScope?: boolean;
  };
  setActivityFilterPeriod: (value: [Date, Date]) => void;
  setActivityFilterScope?: (value: ActivityScope) => void;
}

export const ActivityFilters: React.FC<ActivityFiltersProps> = ({
  filters,
  setActivityFilterPeriod,
  setActivityFilterScope,
}) => {
  const styles = useStyles();

  // TODO: Export this to a separate component
  const filterByPeriodSelectId = useId("filterByPeriodSelect");
  const [selectedPeriod, setSelectedPeriod] = useState<ActivityPeriod>(
    ActivityPeriod.Today
  );

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as ActivityPeriod;
    const range = ActivityPeriodRanges[value];

    setSelectedPeriod(value);
    setActivityFilterPeriod(range);
  };

  // TODO: Export this to a separate component
  const filterByScopeSelectId = useId("filterByScopeSelect");
  const [selectedScope, setSelectedScope] = useState<ActivityScope>(
    ActivityScope.Team
  );

  const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as ActivityScope;

    setSelectedScope(value);

    if (setActivityFilterScope) {
      setActivityFilterScope(value);
    }
  };

  return (
    <div className={styles.root} data-testid="dashboard-activity-filters">
      {/* TODO: Export this to a separate component */}
      {filters?.byScope && (
        <div className={styles.filter}>
          <label htmlFor={filterByScopeSelectId}>Filter by scope</label>
          <Select
            id={filterByScopeSelectId}
            value={selectedScope}
            onChange={handleUserChange}
            data-testid="filter-by-scope"
          >
            <option value={ActivityScope.Personal}>
              {ActivityScopeLabels[ActivityScope.Personal]}
            </option>
            <option value={ActivityScope.Team}>
              {ActivityScopeLabels[ActivityScope.Team]}
            </option>
          </Select>
        </div>
      )}

      {/* TODO: Export this to a separate component */}
      {filters?.byPeriod && (
        <div className={styles.filter}>
          <label htmlFor={filterByPeriodSelectId}>Filter by period</label>
          <Select
            id={filterByPeriodSelectId}
            value={selectedPeriod}
            onChange={handlePeriodChange}
            data-testid="filter-by-period"
          >
            <option value={ActivityPeriod.Today}>
              {ActivityPeriodLabels[ActivityPeriod.Today]}
            </option>
            <option value={ActivityPeriod.Week}>
              {ActivityPeriodLabels[ActivityPeriod.Week]}
            </option>
            <option value={ActivityPeriod.Month}>
              {ActivityPeriodLabels[ActivityPeriod.Month]}
            </option>
            <option value={ActivityPeriod.All}>
              {ActivityPeriodLabels[ActivityPeriod.All]}
            </option>
          </Select>
        </div>
      )}
    </div>
  );
};

export default ActivityFilters;
