/**
 * Important: Keep this file in sync with the backend API
 * api/src/constants/levels.ts
 *
 * NOTE: In the future, we can move this to a database or to shared code package
 */

// Set the base value for a score, used in the formula to calculate the score for a given level
// Currently set to 6, because we want to give 1 pizza (of 6 slices) per level
// NOTE: We can change this later if we want to give more or less pizza per level
export const SCORE_BASE_VALUE = 6;

// Calculate the score for a given level, using a linear progression formula
// Formula: 6 * level + previous levels score
//
// Examples:
// Level 1: 6
// Level 2: 12 + 6 = 18

// Level 3: 18 + 12 + 6 = 36
export const generateUserLevelScores = (level: number): number => {
  let score = 0;

  for (let i = 1; i <= level; i++) {
    score += i * SCORE_BASE_VALUE;
  }

  return score;
};

export const MAX_USER_LEVEL = 15;

export const USER_LEVEL_SCORES: Record<number, number> = {
  1: generateUserLevelScores(1), // 0 + 6 = 6
  2: generateUserLevelScores(2), // 6 + 12 = 18
  3: generateUserLevelScores(3), // 18 + 18 = 36
  4: generateUserLevelScores(4), // 36 + 24 = 60
  5: generateUserLevelScores(5), // 60 + 30 = 90
  6: generateUserLevelScores(6), // 90 + 36 = 126
  7: generateUserLevelScores(7), // 126 + 42 = 168
  8: generateUserLevelScores(8), // 168 + 48 = 216
  9: generateUserLevelScores(9), // 216 + 54 = 270
  10: generateUserLevelScores(10), // 270 + 60 = 330
  11: generateUserLevelScores(11), // 330 + 66 = 396
  12: generateUserLevelScores(12), // 396 + 72 = 468
  13: generateUserLevelScores(13), // 468 + 78 = 546

  // Bonus levels
  14: generateUserLevelScores(14), // 546 + 84 = 630
  15: generateUserLevelScores(15), // 630 + 90 = 720
  // TODO: Make it so that the user won't see the score for the next level, because there is no such level
};

export const USER_LEVEL_LABELS: Record<number, string> = {
  0: "Pizza Peasant",
  1: "Pepperoni Prodigy",
  2: "Cheese Crusader",
  3: "Olives Overlord",
  4: "Mushroom Magician",
  5: "Artichoke Artisan",
  6: "Topping Trooper",
  7: "Ham Houdini",
  8: "Pasta Picasso",
  9: "Veggies Virtuoso",
  10: "BBQ Baron",
  11: "Shrimp Sovereign",
  12: "Garden Guru",
  13: "Supreme Sorcerer",

  // Bonus levels
  14: "Pizza King",
  15: "Pizza Maestro",
};

export const USER_LEVEL_AVATARS: Record<number, string> = {
  "-1": "/images/avatars/default.png",
  0: "/images/avatars/peppy-penguin.png",
  1: "/images/avatars/perky-panda-pepperoni-prodigy.png",
  2: "/images/avatars/cheesy-chameleon-champion.png",
  3: "/images/avatars/olive-overlord.png",
  4: "/images/avatars/mushroom-magician.png",
  5: "/images/avatars/artichoke-artisan.png",
  6: "/images/avatars/topping-trooper.png",
  7: "/images/avatars/ham-houdini.png",
  8: "/images/avatars/pasta-picasso.png",
  9: "/images/avatars/veggie-virtuoso.png",
  10: "/images/avatars/bbq-baron.png",
  11: "/images/avatars/shrimp-sovereign.png",
  12: "/images/avatars/garden-guru.png",
  13: "/images/avatars/supreme-sorcerer.png",

  // Bonus levels
  14: "/images/avatars/pizza-king.png",
  15: "/images/avatars/majestic-monkey-maestro.png",
};
