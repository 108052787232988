import ReactGA from "react-ga4";
import config from "../constants/config";
import { GOOGLE_ANALYTICS_ENVIRONMENTS } from "../constants/global";

interface GoogleAnalyticsHook {
  sendPageView: (options: { page: string; title: string }) => void;
}

export const useGoogleAnalytics = (): GoogleAnalyticsHook => {
  const { env, googleAnalyticsMeasurementId } = config;

  if (
    env &&
    GOOGLE_ANALYTICS_ENVIRONMENTS.includes(env) &&
    googleAnalyticsMeasurementId &&
    ReactGA.isInitialized === false
  ) {
    ReactGA.initialize(googleAnalyticsMeasurementId);
    // ReactGA.initialize([
    //   {
    //     trackingId: googleAnalyticsMeasurementId,
    //     gaOptions: { debug_mode: true },
    //     gtagOptions: {
    //       debug_mode: true,
    //     },
    //   },
    // ]);
  }

  const sendPageView = (options: { page: string; title: string }) => {
    if (ReactGA.isInitialized === false) {
      return;
    }

    ReactGA.send({
      hitType: "pageview",
      page: options.page,
      title: options.title,
    });
  };

  return {
    sendPageView,
  };
};
