import React from "react";
import { SITE_TERMS_OF_USE_URL } from "../constants/site";
/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
class TermsOfUse extends React.Component {
  render() {
    return (
      <div>
        <h1>Terms of Use</h1>
        <p>Please refer to the latest version on our website:</p>
        <ul>
          <li>
            <a
              href={`${SITE_TERMS_OF_USE_URL}`}
              target="_blank"
              rel="noreferrer"
            >
              {SITE_TERMS_OF_USE_URL}
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default TermsOfUse;
