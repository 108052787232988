import { Api } from "./Api";

export type Changelog = {
  id: number;
  status: ChangelogStatus;
  version: string;
  title: string;
  image: string;
  content: string;
  date: Date;
  endorsements: number;
  createdAt: Date;
  updatedAt: Date;
};

export enum ChangelogStatus {
  Draft = "Draft",
  Released = "Released",
}

export class ChangelogApi {
  public static API_ENDPOINT = `changelog`;

  public static async getReleases(): Promise<Changelog[]> {
    const url = `${ChangelogApi.API_ENDPOINT}/releases`;
    const response = await Api.get(url);

    return response;
  }

  public static async endorseChangelog(
    changelogId: number
  ): Promise<Changelog> {
    const url = `${ChangelogApi.API_ENDPOINT}/${changelogId}/endorse`;
    const response = await Api.post(url, {});

    return response;
  }
}
