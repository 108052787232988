import { PropsWithChildren } from "react";
import { useStoreContext } from "../../context/StoreContext";
import { Toaster } from "@fluentui/react-components";

interface ToastNotificationsProps extends PropsWithChildren<{}> {}

export const ToastNotifications: React.FC<ToastNotificationsProps> = ({
  children,
}) => {
  const { toasterId } = useStoreContext();

  return (
    <>
      {children}

      <Toaster toasterId={toasterId} />
    </>
  );
};
