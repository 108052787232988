import { tokens } from "@fluentui/react-components";
import { createTheme, ThemeProvider } from "@fluentui/react";
import { Pagination as FluentUiPagination } from "@fluentui/react-experiments";
import { containerBreakpoints } from "../../utils/breakpoints";
import { useTeamsFxContext } from "../../context/TeamsFxContext";

// TODO: Think about using the dark theme palette from Fluent UI
const darkTheme = createTheme({
  palette: {
    themePrimary: "#ffffff",
    themeLighterAlt: "#f8f8f8",
    themeLighter: "#eaeaea",
    themeLight: "#dcdcdc",
    themeTertiary: "#c0c0c0",
    themeSecondary: "#a3a3a3",
    themeDarkAlt: "#8a8a8a",
    themeDark: "#6f6f6f",
    themeDarker: "#4d4d4d",
    neutralLighterAlt: "#3c3c3c",
    neutralLighter: "#3a3a3a",
    neutralLight: "#363636",
    neutralQuaternaryAlt: "#333333",
    neutralQuaternary: "#313131",
    neutralTertiaryAlt: "#2e2e2e",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#292929",
  },
});

export interface PaginationConfig {
  page: number;
  limit: number;
  total?: number;
}

export interface PaginationProps {
  selectedPage: number;
  pagination: PaginationConfig;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  selectedPage,
  pagination,
  onPageChange,
}) => {
  const { themeString } = useTeamsFxContext();
  const pageCount = Math.ceil(pagination?.total! / pagination.limit);

  return (
    <ThemeProvider theme={themeString === "dark" ? darkTheme : undefined}>
      <FluentUiPagination
        selectedPageIndex={selectedPage}
        pageCount={pageCount}
        itemsPerPage={pagination.limit}
        totalItemCount={pagination.total}
        format={"buttons"}
        previousPageAriaLabel={"Previous page"}
        nextPageAriaLabel={"Next page"}
        firstPageAriaLabel={"Go to First page"}
        lastPageAriaLabel={"Go to Last page"}
        pageAriaLabel={"page"}
        selectedAriaLabel={"selected"}
        onPageChange={onPageChange}
        numberOfPageButton={3}
        styles={{
          root: {
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            alignItems: "center",
            padding: `${tokens.spacingHorizontalM} ${tokens.spacingHorizontalM}`,

            "& > div[role='radiogroup']": {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            },

            ...containerBreakpoints.xs({
              flexDirection: "column",
            }),
          },
          pageNumber: {},
          previousNextPage: {},
          previousNextPageDisabled: {},
          visibleItemLabel: {},
        }}
      />
    </ThemeProvider>
  );
};

export default Pagination;
