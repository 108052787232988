import { useEffect, useMemo } from "react";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
  createDOMRenderer,
  RendererProvider,
} from "@fluentui/react-components";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import Privacy from "./pages/Privacy";
import TermsOfUse from "./pages/TermsOfUse";
import config from "./config";
import { LeaderBoard } from "./tabs/LeaderBoard";
import { Layout } from "./components/Layout";
import { DashBoard } from "./tabs/DashBoard";
import Tab from "./pages/Tab";
import Config from "./pages/Config";
import { TeamsFxContext } from "./context/TeamsFxContext";
import { StoreProvider } from "./context/StoreContext";
import { Achievements } from "./tabs/Achievements";
import { Settings } from "./tabs/Settings";
import { ToastNotifications } from "./components/ToastNotfications";

// https://react.fluentui.dev/?path=/docs/concepts-developer-advanced-configuration--page
const mediaQueryOrder = [
  "(max-width: 1399.98px)",
  "(max-width: 1199.98px)",
  "(max-width: 991.98px)",
  "(max-width: 767.98px)",
  "(max-width: 575.98px)",
];

export const sortMediaQueries = (a: string, b: string): number => {
  return mediaQueryOrder.indexOf(a) - mediaQueryOrder.indexOf(b);
};

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App({ targetDocument }: any) {
  const { loading, theme, themeString, teamsUserCredential, context } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });

  useEffect(() => {
    const buildHash = process.env.REACT_APP_BUILD_HASH ?? "unknown";
    console.log("It's PizzaTime! [%s]", buildHash);

    init();
  }, []);

  const init = async () => {
    await microsoftTeams.app.initialize();
    await microsoftTeams.app.notifySuccess();
  };

  const renderer = useMemo(
    () =>
      createDOMRenderer(targetDocument, {
        compareMediaQueries: sortMediaQueries,
      }),
    [targetDocument]
  );

  return (
    <TeamsFxContext.Provider
      value={{
        theme,
        themeString,
        teamsUserCredential,
        teams: {
          chat: context?.chat,
          channel: context?.channel,
          meeting: context?.meeting,
        },
      }}
    >
      <StoreProvider>
        <RendererProvider renderer={renderer} targetDocument={targetDocument}>
          <FluentProvider
            targetDocument={targetDocument}
            theme={
              themeString === "dark"
                ? teamsDarkTheme
                : themeString === "contrast"
                ? teamsHighContrastTheme
                : {
                    ...teamsLightTheme,
                    colorNeutralBackground3: "#eeeeee",
                  }
            }
            style={{
              background: tokens.colorNeutralBackground3,
              containerType: "inline-size",
              containerName: "root",
            }}
          >
            <Layout>
              <ToastNotifications>
                <Router>
                  {loading ? (
                    <Spinner style={{ margin: 100 }} />
                  ) : (
                    <Routes>
                      <Route path="/privacy" element={<Privacy />} />
                      <Route path="/termsofuse" element={<TermsOfUse />} />
                      <Route path="/config" element={<Config />} />

                      <Route path="/tab" element={<Tab />} />
                      <Route path="/tab-dashboard" element={<DashBoard />} />
                      <Route
                        path="/tab-leaderboard"
                        element={<LeaderBoard />}
                      />
                      <Route
                        path="/tab-achievements"
                        element={<Achievements />}
                      />
                      <Route path="/tab-settings" element={<Settings />} />

                      <Route
                        path="*"
                        element={<Navigate to={"/tab-dashboard"} />}
                      ></Route>
                    </Routes>
                  )}
                </Router>
              </ToastNotifications>
            </Layout>
          </FluentProvider>
        </RendererProvider>
      </StoreProvider>
    </TeamsFxContext.Provider>
  );
}
