import * as React from "react";

import {
  makeStyles,
  Body1,
  Caption1,
  Button as FluentUiButton,
  shorthands,
  tokens,
  Tooltip,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
} from "@fluentui/react-components";
import {
  BugFilled,
  CommentNoteFilled,
  EmojiSadRegular,
  FoodPizzaFilled,
} from "@fluentui/react-icons";
import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
} from "@fluentui/react-components";
import { SITE_CONTACT_US_URL } from "../../../../../constants/site";
import { Changelog, ChangelogApi } from "../../../../../api/ChangelogApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { containerBreakpoints } from "../../../../../utils/breakpoints";
import { formatDate } from "../../../../../utils/helpers";
import { useStoreContext } from "../../../../../context/StoreContext";
import Button from "../../../../../components/Button";
import { QueryKey } from "../../../../../constants/api";
import { useCallback, useEffect } from "react";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    maxWidth: "100%",
  },
  cardPreviewContent: {
    ...shorthands.padding(tokens.spacingVerticalL),
  },
  cardFooter: {
    ...containerBreakpoints.xs({
      flexDirection: "column",
    }),
  },
});

const RECEIVED_FEEDBACK_TITLE = [
  "Delicious!",
  "Yum!",
  "Thank you!",
  "Great!",
  "Awesome!",
  "Delightful!",
  "Fantastic!",
  "Superb!",
];

export const getRandomFeedbackTitle = () =>
  RECEIVED_FEEDBACK_TITLE[
    Math.floor(Math.random() * RECEIVED_FEEDBACK_TITLE.length)
  ];

const RECEIVED_FEEDBACK_MESSAGE = [
  "It's such an honour for me to receive your endorsement. I'm glad you enjoyed the experience!",
  "I'm glad you liked it! I hope you enjoy the next one even more!",
  "Thank you very much for your support! I'm glad you're enjoying the updates.",
  "I'm so happy to hear that you're enjoying the updates! I hope you like the next one even more!",
  "Thank you very much for your endorsement! I'm glad you're enjoying the updates.",
];

export const getRandomFeedbackMessage = () =>
  RECEIVED_FEEDBACK_MESSAGE[
    Math.floor(Math.random() * RECEIVED_FEEDBACK_MESSAGE.length)
  ];

export interface VersionCardProps {
  changelog: Changelog;
}

export const VersionCard: React.FC<VersionCardProps> = ({ changelog }) => {
  const styles = useStyles();

  const { userSettings, dispatchToast } = useStoreContext();

  const queryClient = useQueryClient();
  const {
    mutateAsync: endorseChangelog,
    isPending: isEndorseChangelogPending,
    isError,
    error,
  } = useMutation({
    mutationKey: [QueryKey.EndorseChangelog],
    mutationFn: ChangelogApi.endorseChangelog,
  });

  const handleEndorsementClick = async () => {
    try {
      const response = await endorseChangelog(changelog.id);

      if (response?.id) {
        queryClient.invalidateQueries({
          queryKey: [QueryKey.GetChangeLog],
        });

        dispatchToast(
          <Toast>
            <ToastTitle media={<FoodPizzaFilled />}>
              {getRandomFeedbackTitle()}
            </ToastTitle>
            <ToastBody>{getRandomFeedbackMessage()}</ToastBody>
            <ToastFooter>Thank you very much!</ToastFooter>
          </Toast>,
          {
            intent: "success",
            timeout: 5000,
          }
        );
      }
    } catch (exception: unknown) {
      const error = exception as Error;

      if (error?.message) {
        console.error(error.message);
      }
    }
  };

  const handleEndorsementError = useCallback(() => {
    dispatchToast(
      <Toast>
        <ToastTitle media={<EmojiSadRegular />}>Oops!</ToastTitle>
        <ToastBody>
          Something went wrong while trying to collect your pizza.
        </ToastBody>
        <ToastFooter>Please try again later.</ToastFooter>
      </Toast>,
      {
        intent: "error",
      }
    );
  }, [dispatchToast]);

  useEffect(() => {
    if (isError && error) {
      console.error("Error while collecting changelog endorsement", error);
      handleEndorsementError();
    }
  }, [isError, error, handleEndorsementError]);

  return (
    <Card className={styles.card} data-testid="version-card">
      <CardHeader
        image={<img src={"/images/logo.png"} alt="PizzaTime" width={32} />}
        header={
          <Body1>
            <b>{changelog.title}</b>
          </Body1>
        }
        description={
          <Caption1>
            v{changelog.version} ·{" "}
            {formatDate(changelog.date, userSettings.dateFormat)}
          </Caption1>
        }
      />

      <CardPreview>
        {/* <img
          src={resolveAsset("doc_template.png")}
          alt="Preview of a Word document: About Us - Overview"
        /> */}

        <div
          className={styles.cardPreviewContent}
          dangerouslySetInnerHTML={{ __html: changelog.content }}
        />
      </CardPreview>

      <CardFooter className={styles.cardFooter}>
        <Tooltip
          withArrow
          content="Send me a pizza if you like this update!"
          relationship="label"
        >
          <Button
            isLoading={isEndorseChangelogPending}
            appearance="primary"
            icon={<FoodPizzaFilled fontSize={16} />}
            onClick={handleEndorsementClick}
          >
            {changelog.endorsements > 0
              ? changelog.endorsements
              : "Be the first to endorse me"}
          </Button>
        </Tooltip>
        <FluentUiButton
          icon={<CommentNoteFilled fontSize={16} />}
          as="a"
          href={SITE_CONTACT_US_URL}
          target="_blank"
          rel="noreferrer"
        >
          Send Feedback
        </FluentUiButton>
        <FluentUiButton
          icon={<BugFilled fontSize={16} />}
          as="a"
          href={SITE_CONTACT_US_URL}
          target="_blank"
          rel="noreferrer"
        >
          Report a bug
        </FluentUiButton>
      </CardFooter>
    </Card>
  );
};
