import { FC, useEffect } from "react";
import {
  Body1,
  Field,
  Input,
  Radio,
  RadioGroup,
  Select,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";

import { useTeamsFxContext } from "../../../context/TeamsFxContext";
import { TeamApi } from "../../../api/TeamApi";
import { Chat, ChatApi } from "../../../api/ChatApi";
import { Alert } from "../../../components/Alert";
import { useUserProfile } from "../../DashBoard/hooks/useUserProfile";
import classNames from "classnames";
import Button from "../../../components/Button";
import { QueryKey } from "../../../constants/api";
import { TabChatType, TabTeamType } from "../types";
import { SetupChatFormValidationSchema } from "../utils/validationSchema";

const MAP_CHAT_TYPE: Record<TabChatType, string> = {
  [TabChatType.GroupChat]: "Group Chat",
  [TabChatType.ChannelChat]: "Channel",
  [TabChatType.MeetingChat]: "Meeting",
};

export interface SetupChatForm {
  providerTenantId: string;
  providerChatId: string;
  providerChatType: TabChatType;
  assignChatTo: TabTeamType;
  teamId: string; // Note: IDs are numbers, but here we do support a custom value ":create-new-team:", and thus the type is string
  teamName: string;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalL),
  },
  hiddenFields: {
    display: "none",
  },
  formFieldError: {
    backgroundColor: tokens.colorStatusDangerBackground1,
    ...shorthands.border("1", "solid", tokens.colorStatusDangerBorder1),
  },
  formFieldErrorMessage: {
    color: tokens.colorStatusDangerForeground1,
  },
});

export interface ChatFormProps {
  chat?: Chat;
  onSuccess: () => void;
}

const ChatForm: FC<ChatFormProps> = ({ chat, onSuccess }) => {
  const styles = useStyles();

  const { teams } = useTeamsFxContext();
  const { teamsUser } = useUserProfile();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<SetupChatForm>({
    resolver: yupResolver(
      SetupChatFormValidationSchema
    ) as Resolver<SetupChatForm>,
    defaultValues: {
      assignChatTo: TabTeamType.Tenant,
    },
  });

  const values = watch();

  useEffect(() => {
    if (teams?.channel) {
      setValue("providerChatId", teams.channel.id);
      setValue("providerChatType", TabChatType.ChannelChat);
      setValue("teamName", teams.channel.displayName);
    } else if (teams?.meeting) {
      setValue("providerChatId", teams.meeting.id);
      setValue("providerChatType", TabChatType.MeetingChat);
    } else if (teams?.chat) {
      setValue("providerChatId", teams.chat.id);
      setValue("providerChatType", TabChatType.GroupChat);
    }
  }, [teams, setValue]);

  useEffect(() => {
    if (teamsUser) {
      setValue("providerTenantId", teamsUser.tenantId);
    }
  }, [teamsUser, setValue]);

  useEffect(() => {
    if (chat) {
      if (chat.team?.id) {
        setValue("assignChatTo", TabTeamType.Team);
        setValue("teamId", chat.team.id.toString());
      } else {
        setValue("assignChatTo", TabTeamType.Tenant);
      }
    }
  }, [chat, setValue]);

  const {
    isPending: isChatSetupPending,
    mutateAsync: setupChat,
    isError,
    error,
  } = useMutation({
    mutationKey: [QueryKey.SetupChat],
    mutationFn: ChatApi.setupChat,
  });

  // Get the Tenant from the API
  const { data: availableTeams } = useQuery({
    queryKey: [QueryKey.GetTeams, teamsUser?.tenantId],
    queryFn: () => TeamApi.getTeams(teamsUser?.tenantId),
    enabled: !!teamsUser?.tenantId,
    retry: false,
  });

  const onSubmit: SubmitHandler<SetupChatForm> = async (
    data: SetupChatForm
  ) => {
    try {
      const response = await setupChat(data);

      if (response?.id) {
        onSuccess();
      }
    } catch (exception: unknown) {
      const error = exception as Error;

      if (error.message) {
        console.error(error.message);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.form}
      data-testid="setup--chat-form"
    >
      <div className={styles.hiddenFields}>
        <input
          {...register("providerTenantId")}
          data-testid="setup--chat-form-provider-tenant-id"
        />
        <input
          {...register("providerChatId")}
          data-testid="setup--chat-form-provider-chat-id"
        />
        <input
          {...register("providerChatType")}
          data-testid="setup--chat-form-provider-chat-type"
        />
      </div>

      <Field
        label={`Do you want to assign this ${
          values.providerChatType
            ? MAP_CHAT_TYPE[values.providerChatType]
            : "chat"
        } to a specific Team?`}
      >
        <RadioGroup
          value={values.assignChatTo}
          onChange={(_, data) =>
            setValue("assignChatTo", data.value as TabTeamType)
          }
          data-testid="setup--chat-form-assign-chat-to"
        >
          <Radio
            value={TabTeamType.Tenant}
            label="No, attach it to the global company leaderboard (Default))"
            data-testid="setup--chat-form-assign-chat-to-tenant"
          />
          <Radio
            value={TabTeamType.Team}
            label="Yes, assign it to a Team leaderboard (suitable for big companies)"
            data-testid="setup--chat-form-assign-chat-to-team"
          />
        </RadioGroup>
      </Field>

      {values.assignChatTo === TabTeamType.Team && (
        <Field label="Select a Team" required>
          <Select
            value={values.teamId}
            onChange={(_, data) => setValue("teamId", data.value)}
          >
            <option value="">Pick from the options</option>
            {availableTeams?.map((team) => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
            <option value=":create-new-team:">Create a new Team</option>
          </Select>
        </Field>
      )}

      {values.assignChatTo === TabTeamType.Team &&
        values.teamId === ":create-new-team:" && (
          <Field label="Team Name" required>
            <Input
              {...register("teamName")}
              data-testid="setup--chat-form-new-team-name"
              className={classNames({
                [styles.formFieldError]: isDirty && !!errors?.teamName,
              })}
            />
            {isDirty && errors?.teamName && (
              <Body1 className={styles.formFieldErrorMessage}>
                {errors?.teamName?.message}
              </Body1>
            )}
          </Field>
        )}

      {isError && error && (
        <Alert
          intent="error"
          title="Oh no, something went wrong!"
          message="We couldn't set up the tab right now. Please try again later."
        />
      )}

      <Button isLoading={isChatSetupPending} appearance="primary" type="submit">
        Submit
      </Button>
    </form>
  );
};

export default ChatForm;
