import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import {
  ActivityPeriod,
  ActivityPeriodRanges,
} from "../../../shared/components/Activity/ActivityFilters";

const DashboardContext = createContext<{
  activityFilterPeriod: [Date, Date];
  setActivityFilterPeriod: (value: [Date, Date]) => void;
}>({
  activityFilterPeriod: ActivityPeriodRanges[ActivityPeriod.Today],
  setActivityFilterPeriod: () => {},
});

interface DashboardContextProviderProps extends PropsWithChildren<{}> {}

export const DashboardProvider: FC<DashboardContextProviderProps> = ({
  children,
}) => {
  const [activityFilterPeriod, setActivityFilterPeriod] = useState<
    [Date, Date]
  >(ActivityPeriodRanges[ActivityPeriod.Today]);

  return (
    <DashboardContext.Provider
      value={{
        activityFilterPeriod,
        setActivityFilterPeriod,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider"
    );
  }

  return context;
};
