import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

const UserProfileDialogContext = createContext<{
  isUserProfileDialogOpen: boolean;
  setIsUserProfileDialogOpen: (value: boolean) => void;

  selectedProviderUserId: string | null | undefined;
  setSelectedProviderUserId: (value: string | null | undefined) => void;
}>({
  isUserProfileDialogOpen: false,
  setIsUserProfileDialogOpen: () => {},

  selectedProviderUserId: null,
  setSelectedProviderUserId: () => {},
});

interface UserProfileDialogContextProviderProps extends PropsWithChildren<{}> {}

export const UserProfileDialogProvider: FC<
  UserProfileDialogContextProviderProps
> = ({ children }) => {
  const [isUserProfileDialogOpen, setIsUserProfileDialogOpen] =
    useState<boolean>(false);
  const [selectedProviderUserId, setSelectedProviderUserId] = useState<
    string | null
  >();

  return (
    <UserProfileDialogContext.Provider
      value={{
        isUserProfileDialogOpen,
        setIsUserProfileDialogOpen,

        selectedProviderUserId,
        setSelectedProviderUserId,
      }}
    >
      {children}
    </UserProfileDialogContext.Provider>
  );
};

export const useUserProfileDialogContext = () => {
  const context = useContext(UserProfileDialogContext);

  if (!context) {
    throw new Error(
      "useUserProfileDialogContext must be used within a UserProfileDialogProvider"
    );
  }

  return context;
};
