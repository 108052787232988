import {
  Select,
  makeStyles,
  shorthands,
  tokens,
  useId,
} from "@fluentui/react-components";
import { containerBreakpoints } from "../../../utils/breakpoints";
import { useAchievementsContext } from "../context/AchievementsContext";
import { AchievementType } from "../../../api/AchievementApi";

interface GlobalFiltersProps {}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap(tokens.spacingVerticalM),
    ...containerBreakpoints.xs({
      width: "100%",
      justifyContent: "space-between",
      ...shorthands.margin(tokens.spacingVerticalL, 0),
    }),
  },
});

const GlobalFilters: React.FC<GlobalFiltersProps> = () => {
  const styles = useStyles();
  const filterByTypeSelectId = useId("filterByTypeSelect");

  const { type, setType } = useAchievementsContext();

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setType(event.target.value);
  };

  return (
    <div className={styles.root}>
      <label htmlFor={filterByTypeSelectId}>Filter by type</label>
      <Select
        id={filterByTypeSelectId}
        value={type}
        onChange={handleTypeChange}
      >
        <option value={""}>All</option>
        <option value={AchievementType.Generic}>General</option>
        <option value={AchievementType.PizzaMastery}>Pizza Mastery</option>
        <option value={AchievementType.IngredientsCollector}>
          Ingredients Collector
        </option>
        <option value={AchievementType.Counter}>Counters</option>
        <option value={AchievementType.Other}>Other</option>
      </Select>
    </div>
  );
};

export default GlobalFilters;
