import {
  AchievementType,
  UserAchievement,
  UserAchievementStatus,
} from "../../../api/AchievementApi";
import { capitalize } from "../../../utils/helpers";

export const MEDALS_COMPATIBLE_ACHIEVEMENTS: AchievementType[] = [
  AchievementType.PizzaMastery,
  AchievementType.IngredientsCollector,
  AchievementType.Counter,
];

export const DEFAULT_ACHIEVEMENT_IMAGE = "/images/achievements/default.png";
const MEDALS_ACHIEVEMENT_IMAGE =
  "/images/achievements/:achievementType:/:achievementSlug:/:achievementStatus:.png";

export const getAchievementImage = (
  userAchievement: UserAchievement
): string => {
  const configuration = userAchievement.achievement.configuration;

  if (
    MEDALS_COMPATIBLE_ACHIEVEMENTS.includes(userAchievement.achievement.type)
  ) {
    return MEDALS_ACHIEVEMENT_IMAGE.replace(
      ":achievementType:",
      userAchievement.achievement.type
    )
      .replace(":achievementSlug:", userAchievement.achievement.slug)
      .replace(":achievementStatus:", userAchievement.status);
  }

  return configuration?.image ?? DEFAULT_ACHIEVEMENT_IMAGE;
};

export const getAchievementStatus = (
  userAchievement: UserAchievement
): string => {
  if (
    MEDALS_COMPATIBLE_ACHIEVEMENTS.includes(userAchievement.achievement.type)
  ) {
    if (
      userAchievement.achievement.type === AchievementType.PizzaMastery &&
      userAchievement.status !== UserAchievementStatus.NotStarted &&
      userAchievement.status !== UserAchievementStatus.InProgress
    ) {
      return `Mastery Level - ${capitalize(userAchievement.status)}`;
    } else if (
      userAchievement.achievement.type ===
        AchievementType.IngredientsCollector &&
      userAchievement.status !== UserAchievementStatus.NotStarted &&
      userAchievement.status !== UserAchievementStatus.InProgress
    ) {
      return `Collector Level - ${capitalize(userAchievement.status)}`;
    } else if (
      userAchievement.achievement.type === AchievementType.Counter &&
      userAchievement.status !== UserAchievementStatus.NotStarted &&
      userAchievement.status !== UserAchievementStatus.InProgress
    ) {
      return `Progress Level - ${capitalize(userAchievement.status)}`;
    }
  }

  return "";
};

export const getAchievementProgress = (
  userAchievement: UserAchievement
): string => {
  if (
    MEDALS_COMPATIBLE_ACHIEVEMENTS.includes(userAchievement.achievement.type)
  ) {
    if (userAchievement.status !== UserAchievementStatus.Gold) {
      const configuration =
        userAchievement.achievement.configuration.states![
          userAchievement.status
        ];

      const currentProgress = userAchievement.progress - configuration.progress;
      const nextLevel = configuration?.step!;

      return `${currentProgress}/${nextLevel}`;
    }
  }
  return "";
};

export const getAchievementType = (achievement: AchievementType): string => {
  switch (achievement) {
    case AchievementType.PizzaMastery:
      return "Pizza Mastery";
    case AchievementType.IngredientsCollector:
      return "Ingredients Collector";
    case AchievementType.Other:
      return "Easter Egg";
    case AchievementType.Generic:
    case AchievementType.Counter:
    default:
      return "Achievement";
  }
};
