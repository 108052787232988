import { useState } from "react";
import { LeaderBoard } from "../tabs/LeaderBoard";
import {
  Divider,
  Overflow,
  OverflowItem,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { Settings } from "../tabs/Settings";
import { OverflowMenu, TabConfig } from "../components/Tabs";
import { Overview } from "../tabs/Overview";

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("0px", "20px"),
    rowGap: "20px",
  },
  tabsContainer: {
    ...shorthands.overflow("hidden"),
    height: "fit-content",
    minWidth: "150px",
    // resize: "horizontal",
    width: "100%",
    zIndex: 0,
  },
  tabList: {},
  panels: {
    ...shorthands.padding(0, "10px"),
    width: "100%",
    "& th": {
      textAlign: "left",
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

const tabs: TabConfig[] = [
  {
    id: "overview",
    name: "Overview",
  },
  // TODO: Temporarily disabled due to Teams Store review issue
  // {
  //   id: "dashboard",
  //   name: "Dashboard",
  // },
  {
    id: "leaderboard",
    name: "Leaderboard",
  },
  // TODO: Temporarily disabled due to Teams Store review issue
  // {
  //   id: "achievements",
  //   name: "Achievements",
  // },
  {
    id: "settings",
    name: "Settings",
  },
];

export default function TabPage() {
  const styles = useStyles();

  const [selectedValue, setSelectedValue] = useState<TabValue>("overview");

  const onTabSelect = (selectedTabId: string) => {
    setSelectedValue(selectedTabId);
  };

  return (
    <div className={styles.root}>
      <div className={styles.tabsContainer}>
        <Overflow minimumVisible={2}>
          <TabList
            selectedValue={selectedValue}
            onTabSelect={(_event: SelectTabEvent, data: SelectTabData) =>
              onTabSelect(data.value as string)
            }
            className={styles.tabList}
          >
            {tabs.map((tab) => {
              return (
                <OverflowItem
                  key={tab.id}
                  id={tab.id}
                  priority={tab.id === selectedValue ? 2 : 1}
                >
                  <Tab value={tab.id}>{tab.name}</Tab>
                </OverflowItem>
              );
            })}
            <OverflowMenu tabs={tabs} onTabSelect={onTabSelect} />
          </TabList>
        </Overflow>
      </div>

      <Divider />

      <div className={styles.panels}>
        {selectedValue === "overview" && (
          <div role="tabpanel" aria-labelledby="Overview">
            <Overview />
          </div>
        )}
        {/* // TODO: Temporarily disabled due to Teams Store review issue
        {selectedValue === "dashboard" && (
          <div role="tabpanel" aria-labelledby="Dashboard">
            <DashBoard />
          </div>
        )} */}
        {selectedValue === "leaderboard" && (
          <div role="tabpanel" aria-labelledby="Leaderboard">
            <LeaderBoard />
          </div>
        )}
        {/* // TODO: Temporarily disabled due to Teams Store review issue
        {selectedValue === "achievements" && (
          <div role="tabpanel" aria-labelledby="Achievements">
            <Achievements />
          </div>
        )} */}
        {selectedValue === "settings" && (
          <div role="tabpanel" aria-labelledby="Settings">
            <Settings />
          </div>
        )}
      </div>
    </div>
  );
}
