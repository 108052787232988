import React, { useEffect, useState } from "react";

import LeaderBoardTable, { LeaderBoardTableRow } from "./LeaderBoardTable";
import { useQuery } from "@tanstack/react-query";
import { LeaderBoardApi } from "../../../api/LeaderBoardApi";
import {
  buildLeaderBoardFilters,
  mapLeaderBoardUsersToTableRows,
} from "../utils/helpers";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { useLeaderBoardContext } from "../context/LeaderBoardContext";
import { DEFAULT_PAGE_SIZE } from "../../../constants/global";
import Pagination, { PaginationConfig } from "../../../components/Pagination";
import { TableColumn } from "../../../components/Table";
import { QueryKey } from "../../../constants/api";

export const RECIPIENTS_LEADER_BOARD_COLUMNS: TableColumn[] = [
  { columnKey: "rank", label: "Rank", width: "50px" },
  { columnKey: "name", label: "Name" },
  { columnKey: "score", label: "Score", width: "60px" },
];

const useStyles = makeStyles({
  root: {
    ...shorthands.padding(tokens.spacingVerticalL, 0),
  },
});

export const RecipientsLeaderBoard = () => {
  const styles = useStyles();
  const [rows, setRows] = useState<LeaderBoardTableRow[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationConfig>({
    page: 0,
    limit: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const { teamId } = useLeaderBoardContext();
  const queryParams = {
    filters: buildLeaderBoardFilters(teamId),
    pagination: {
      page: selectedPage + 1,
      limit: pagination.limit,
    },
  };

  const { isLoading, isError, isSuccess, data, refetch } = useQuery({
    queryKey: [QueryKey.GetLeaderBoardRecipients, teamId, selectedPage],
    queryFn: () => LeaderBoardApi.getLeaderBoardRecipients(queryParams),
  });

  useEffect(() => {
    if (data) {
      setRows(mapLeaderBoardUsersToTableRows(data.data, data.pagination));
      setPagination(data.pagination);
    }
  }, [data]);

  const onPageChange = (index: number) => {
    setSelectedPage(index);
  };

  return (
    <div className={styles.root} data-testid="leaderboard-recipients-table">
      <LeaderBoardTable
        title="Top Recipients"
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
        onRefresh={refetch}
        columns={RECIPIENTS_LEADER_BOARD_COLUMNS}
        rows={rows}
      />

      {(pagination?.total && pagination.total > pagination.limit) === true && (
        <Pagination
          selectedPage={selectedPage}
          pagination={pagination}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};
