import { UserSettings } from "../../../../api/UserApi";

export enum ConfigurationDateFormat {
  Default = "locale",
  Example1 = "yyyy-MM-dd",
  Example2 = "dd/MM/yyyy",
  Example3 = "dd.MM.yyyy",
  Example4 = "MM/dd/yyyy",
  Example5 = "MMM d, yyyy",
  Example6 = "MMMM d, yyyy",
  Example7 = "EEEE, MMMM d, yyyy",
}

export enum ConfigurationTimeFormat {
  Default = "locale",
  Example1 = "hh:mm a",
  Example2 = "h:m a",
  Example3 = "HH:mm",
  Example4 = "H:m",
}

export const DEFAULT_CONFIGURATION_NOTIFICATIONS = {
  WeeklySummary: true,
  WeeklyReminder: true,
};

export const DEFAULT_USER_CONFIGURATION: UserSettings = {
  dateFormat: ConfigurationDateFormat.Default,
  timeFormat: ConfigurationTimeFormat.Default,
  notifications: {
    weeklySummary: DEFAULT_CONFIGURATION_NOTIFICATIONS.WeeklySummary,
    weeklyReminder: DEFAULT_CONFIGURATION_NOTIFICATIONS.WeeklyReminder,
  },
};
