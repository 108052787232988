import { Api } from "./Api";

export enum FeatureFlag {
  SettingsNotifications = "teams-tab--settings-notifications",
}

export interface GetFeatureFlagResponse {
  flag: FeatureFlag;
  value: boolean;
}

export class FeatureFlagApi {
  public static API_ENDPOINT = `app/feature-flag`;

  public static async getFeatureFlag(
    flagName: FeatureFlag
  ): Promise<GetFeatureFlagResponse> {
    const url = `${FeatureFlagApi.API_ENDPOINT}/${flagName}`;
    const response = await Api.get(url);

    return response;
  }
}
