import { useEffect } from "react";
import {
  Select,
  makeStyles,
  shorthands,
  tokens,
  useId,
} from "@fluentui/react-components";
import { useUserProfile } from "../../DashBoard/hooks/useUserProfile";
import { useLeaderBoardContext } from "../context/LeaderBoardContext";
import { useQuery } from "@tanstack/react-query";
import { TeamApi } from "../../../api/TeamApi";
import { containerBreakpoints } from "../../../utils/breakpoints";
import { QueryKey } from "../../../constants/api";
import { ChatApi } from "../../../api/ChatApi";
import { getTeamsContextChatId } from "../../../utils/helpers";
import { useTeamsFxContext } from "../../../context/TeamsFxContext";

interface GlobalFiltersProps {}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap(tokens.spacingVerticalM),
    ...containerBreakpoints.xs({
      width: "100%",
      justifyContent: "space-between",
      ...shorthands.margin(tokens.spacingVerticalL, 0),
    }),
  },
});

// TODO: Add a test for this component
const GlobalFilters: React.FC<GlobalFiltersProps> = () => {
  const styles = useStyles();
  const filterByTeamSelectId = useId("filterByTeamSelect");

  const { teamsUser } = useUserProfile();
  const { teamId, setTeamId } = useLeaderBoardContext();

  const { data: teams } = useQuery({
    queryKey: [QueryKey.GetTeams, teamsUser?.tenantId],
    queryFn: () => TeamApi.getTeams(teamsUser?.tenantId),
    enabled: !!teamsUser?.tenantId,
  });

  const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value, 10);

    setTeamId(value);
  };

  const { teams: teamsContext } = useTeamsFxContext();
  const providerChatId = getTeamsContextChatId(teamsContext);

  const { data: chat } = useQuery({
    queryKey: [QueryKey.GetChat, providerChatId],
    queryFn: () => ChatApi.getChat(providerChatId),
    enabled: !!providerChatId,
  });

  useEffect(() => {
    if (chat && chat.team) {
      setTeamId(chat.team.id);
    }
  }, [chat, setTeamId]);

  return (
    <div className={styles.root} data-testid="leaderboard--filters">
      <label htmlFor={filterByTeamSelectId}>Filter by Team</label>
      <Select
        id={filterByTeamSelectId}
        value={teamId}
        onChange={handleTeamChange}
      >
        <option value={0}>Global</option>

        {teams?.map((team) => (
          <option key={team.id} value={team.id}>
            {team.name}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default GlobalFilters;
