import {
  LeaderBoardFilters,
  LeaderBoardUser,
} from "../../../api/LeaderBoardApi";
import { PaginationConfig } from "../../../components/Pagination";
import { LeaderBoardTableRow } from "../components/LeaderBoardTable";

export const mapLeaderBoardUsersToTableRows = (
  recipientsLeaderBoard: LeaderBoardUser[],
  pagination: PaginationConfig
): LeaderBoardTableRow[] => {
  const { page, limit } = pagination;
  const rankOffset = (page - 1) * limit;

  const rows: LeaderBoardTableRow[] = recipientsLeaderBoard.map(
    (recipient, index) => {
      return {
        key: index,
        rank: index + rankOffset + 1,
        name: recipient.name,
        score: recipient.score,
        providerUserId: recipient.providerUserId,
      };
    }
  );

  return rows;
};

export const buildLeaderBoardFilters = (
  teamId: number | null
): LeaderBoardFilters => {
  let filters = {};

  if (teamId) {
    filters = {
      teamId,
    };
  }

  return filters;
};
