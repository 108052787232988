import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import {
  ActivityPeriod,
  ActivityPeriodRanges,
  ActivityScope,
} from "../../../shared/components/Activity/ActivityFilters";

const OverviewContext = createContext<{
  activityFilterPeriod: [Date, Date];
  setActivityFilterPeriod: (value: [Date, Date]) => void;

  activityFilterScope: ActivityScope;
  setActivityFilterScope: (value: ActivityScope) => void;
}>({
  activityFilterPeriod: ActivityPeriodRanges[ActivityPeriod.Today],
  setActivityFilterPeriod: () => {},

  activityFilterScope: ActivityScope.Team,
  setActivityFilterScope: () => {},
});

interface OverviewContextProviderProps extends PropsWithChildren<{}> {}

export const OverviewProvider: FC<OverviewContextProviderProps> = ({
  children,
}) => {
  const [activityFilterPeriod, setActivityFilterPeriod] = useState<
    [Date, Date]
  >(ActivityPeriodRanges[ActivityPeriod.Today]);
  const [activityFilterScope, setActivityFilterScope] = useState<ActivityScope>(
    ActivityScope.Team
  );

  return (
    <OverviewContext.Provider
      value={{
        activityFilterPeriod,
        setActivityFilterPeriod,

        activityFilterScope,
        setActivityFilterScope,
      }}
    >
      {children}
    </OverviewContext.Provider>
  );
};

export const useOverviewContext = () => {
  const context = useContext(OverviewContext);

  if (!context) {
    throw new Error(
      "useOverviewContext must be used within a OverviewProvider"
    );
  }

  return context;
};
