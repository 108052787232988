import React, { PropsWithChildren } from "react";

import { makeStyles, shorthands, Title3 } from "@fluentui/react-components";
import { Card, CardHeader, CardPreview } from "@fluentui/react-components";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    maxWidth: "100%",
  },
});

interface TabPanelProps extends PropsWithChildren<{}> {
  title: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({ title, children }) => {
  const styles = useStyles();

  return (
    <Card className={styles.card}>
      <CardHeader header={<Title3>{title}</Title3>} />

      <CardPreview>{children}</CardPreview>
    </Card>
  );
};
