import React from "react";
import { EndorsementType, UserActivity } from "../../../api/EndorsementApi";
import { ActivityTableRow } from "../../../shared/components/Activity/ActivityTable";
import {
  People20Regular,
  PersonArrowLeft20Regular,
  PersonArrowRight20Regular,
} from "@fluentui/react-icons";
import { ActivityMessagePlaceholder } from "../../../shared/components/Activity/ActivityTableMessage";

export const generateUserActivityIcon = (
  activity: UserActivity
): React.ReactElement => {
  switch (activity.type) {
    case EndorsementType.Neutral:
      return <People20Regular />;
    case EndorsementType.Sender:
      return <PersonArrowRight20Regular />;
    case EndorsementType.Recipient:
      return <PersonArrowLeft20Regular />;
    default:
      return <></>;
  }
};

export const generateUserActivityMessage = (activity: UserActivity): string => {
  const tokenMessage = generateEndorsementTokenMessage(activity);

  switch (activity.type) {
    case EndorsementType.Neutral:
      return `${ActivityMessagePlaceholder.SenderUserPlaceholder} endorsed ${ActivityMessagePlaceholder.TargetUserPlaceholder} with ${tokenMessage}`;
    case EndorsementType.Sender:
      return `You sent ${tokenMessage} to ${ActivityMessagePlaceholder.TargetUserPlaceholder}.`;
    case EndorsementType.Recipient:
      return `You were endorsed by ${ActivityMessagePlaceholder.TargetUserPlaceholder} with ${tokenMessage}!`;
    default:
      return "";
  }
};

// TODO: Make it handle multiple tokens (pizza, burger, etc.)
const generateEndorsementTokenMessage = (activity: UserActivity): string => {
  if (activity.score === 1) {
    return `a 🍕`;
  }

  return `${activity.score} 🍕`;
};

export const mapUsersActivityToTableRows = (
  usersActivity: UserActivity[]
): ActivityTableRow[] => {
  const rows: ActivityTableRow[] = usersActivity.map((activity, index) => {
    return {
      activity,
      key: index,
      icon: generateUserActivityIcon(activity),
      message: generateUserActivityMessage(activity),
      date: new Date(activity.date),
    };
  });

  return rows;
};
