import React from "react";
import { SITE_PRIVACY_POLICY_URL } from "../constants/site";
/**
 * This component is used to display the required
 * privacy statement which can be found in a link in the
 * about tab.
 */
class Privacy extends React.Component {
  render() {
    return (
      <div>
        <h1>Privacy Policy</h1>
        <p>Please refer to the latest version on our website:</p>
        <ul>
          <li>
            <a
              href={`${SITE_PRIVACY_POLICY_URL}`}
              target="_blank"
              rel="noreferrer"
            >
              {SITE_PRIVACY_POLICY_URL}
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Privacy;
