import { PaginationConfig } from "../components/Pagination";
import { Api } from "./Api";

export interface LeaderBoardUser {
  id: number; // TODO: Maybe not used?
  name: string;
  score: number;
  providerUserId: string;
}

export interface GetLeaderBoardRequestParams {
  filters: LeaderBoardFilters;
  pagination: PaginationConfig;
}

export interface LeaderBoardFilters {
  teamId?: number;
}

export type GetLeaderBoardResponse = {
  data: LeaderBoardUser[];
  pagination: PaginationConfig;
};

export class LeaderBoardApi {
  public static API_ENDPOINT = `leaderboard`;

  public static async getLeaderBoardRecipients(
    params: GetLeaderBoardRequestParams
  ): Promise<GetLeaderBoardResponse> {
    const url = `${LeaderBoardApi.API_ENDPOINT}/recipients`;
    const queryParams = {
      ...params.filters,
      ...params.pagination,
    };

    const response = await Api.get(url, queryParams);

    return response;
  }

  public static async getLeaderBoardEndorsers(
    params: GetLeaderBoardRequestParams
  ): Promise<GetLeaderBoardResponse> {
    const url = `${LeaderBoardApi.API_ENDPOINT}/endorsers`;
    const queryParams = {
      ...params.filters,
      ...params.pagination,
    };

    const response = await Api.get(url, queryParams);

    return response;
  }
}
