import * as yup from "yup";

export const SendUserFeedbackFormValidationSchema = yup
  .object()
  .shape({
    id: yup.number().nullable(),
    providerUserId: yup.string().nullable(),
    email: yup.string().email().nullable(),
    message: yup.string().required(),
    endorsement: yup.boolean(),
    score: yup.number().min(0).max(5),
  })
  .required();
