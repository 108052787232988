import {
  Badge,
  Tooltip,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { FoodPizzaRegular } from "@fluentui/react-icons";

interface DailyRecipeQuantityBadgeProps {
  quantityRemaining: number;
  totalQuantity: number;
}

const useStyles = makeStyles({
  badge: {
    ...shorthands.margin(0, tokens.spacingHorizontalS),
  },
});

export const DailyRecipeQuantityBadge: React.FC<
  DailyRecipeQuantityBadgeProps
> = ({ quantityRemaining, totalQuantity }) => {
  const styles = useStyles();

  const getBadgeColor = () => {
    if (quantityRemaining === 0) {
      return "danger";
    } else if (quantityRemaining === 1) {
      return "warning";
    } else {
      return "success";
    }
  };

  const getTooltipInfo = () => {
    if (quantityRemaining === 0) {
      return "You have no more pizzas to give out today. Come back tomorrow!";
    }

    return `${quantityRemaining} pizzas remaining to give away today`;
  };

  return (
    <Tooltip
      content={getTooltipInfo()}
      relationship="description"
      withArrow={true}
    >
      <Badge color={getBadgeColor()} className={styles.badge}>
        {quantityRemaining === 0 ? (
          "Out of pizza"
        ) : (
          <FoodPizzaRegular
            style={{ position: "relative", top: "0px", right: "2px" }}
          />
        )}

        {quantityRemaining > 0 && (
          <>
            {quantityRemaining}/{totalQuantity}
          </>
        )}
      </Badge>
    </Tooltip>
  );
};
