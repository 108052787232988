import * as yup from "yup";
import { UserRole } from "../../../../../api/UserApi";

export const EditUserFormValidationSchema = yup
  .object()
  .shape({
    id: yup.number().required(),
    providerUserId: yup.string().required(),
    name: yup.string().required(),
    role: yup
      .string()
      .required()
      .oneOf(Object.values(UserRole), "Please select a valid role."),
  })
  .required();
