import React from "react";
import {
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

export interface AlertProps {
  title?: string;
  message?: string;
  intent?: "error" | "success" | "warning" | "info";
  actions?: React.ReactNode;
}

const useStyles = makeStyles({
  messageBar: {
    display: "flex !important",
    alignItems: "flex-start",
    maxWidth: "100%",
    ...shorthands.padding(tokens.spacingHorizontalS),

    "& .fui-MessageBar__icon svg": {
      width: "2.5rem",
      height: "2.5rem",
    },
  },
  messageBarBody: {
    ...shorthands.padding(0),
  },
  messageBarContent: {
    ...shorthands.margin(0),
  },
});

export const Alert: React.FC<AlertProps> = ({
  title = "Oops! Something went wrong...",
  message = "An error occurred while fetching data. Please try again later 😔",
  intent = "info",
  actions = null,
}) => {
  const styles = useStyles();
  return (
    <MessageBar key={title} intent={intent} className={styles.messageBar}>
      <MessageBarBody className={styles.messageBarBody}>
        <MessageBarTitle>{title}</MessageBarTitle>
        <p className={styles.messageBarContent}>{message}</p>
      </MessageBarBody>

      {actions}
    </MessageBar>
  );
};
