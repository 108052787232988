import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useAppInsights } from "./hooks/useAppInsights";
import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics";

import App from "./App";
import "./index.css";

// Create an API client
const queryClient = new QueryClient();

useAppInsights();
useGoogleAnalytics();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);
