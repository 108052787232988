import React from "react";
import {
  Body1,
  Card,
  CardPreview,
  Subtitle1,
  Subtitle2,
  Tooltip,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { ErrorCircle20Regular, FoodPizzaRegular } from "@fluentui/react-icons";
import {
  MAX_USER_LEVEL,
  USER_LEVEL_AVATARS,
  USER_LEVEL_LABELS,
  USER_LEVEL_SCORES,
} from "../../../constants/levels";
import { LineSkeleton } from "../../../components/Skeletons/LineSkeleton";
import { useUserProfile } from "../hooks/useUserProfile";
import { containerBreakpoints } from "../../../utils/breakpoints";

interface ProfileProps {}

const useStyles = makeStyles({
  card: {
    display: "flex",
    width: "300px",
    height: "auto",
    ...shorthands.margin("auto"),
    ...containerBreakpoints.xs({
      width: "100%",
    }),
  },
  cardPreview: {
    ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
  },
  userAvatar: {
    aspectRatio: "1 / 1",
    height: "auto !important",
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    ...shorthands.margin(0, "auto", tokens.spacingVerticalM, "auto"),
  },
  userInfo: {
    ...containerBreakpoints.xs({
      textAlign: "center",
    }),
  },
  profileInfo: {
    ...containerBreakpoints.xs({
      display: "flex !important",
      flexDirection: "column",
      alignItems: "center",
    }),
  },
  userErrorTooltip: {
    color: tokens.colorPaletteRedBackground3,
    position: "relative",
    top: "3px",
    left: "4px",
    cursor: "pointer",
  },
  scorePoints: {
    marginLeft: "5px",
  },
});

export const Profile: React.FC<ProfileProps> = () => {
  const styles = useStyles();

  const {
    // Teams user
    isLoadingTeamsUser,
    teamsUser,

    // User
    isLoadingUser,
    isSuccessUser,
    isErrorUser,
    user,
    refetchUser,
  } = useUserProfile();

  // Aliases for easier usage later
  const userAvatar = USER_LEVEL_AVATARS[user?.level ?? -1];
  const userName = user?.name ?? teamsUser?.displayName;
  const userLevel = user?.level ?? 0;
  const userLevelLabel = USER_LEVEL_LABELS[userLevel];
  const userScore = user?.score ?? 0;
  const userNextLevelScore = USER_LEVEL_SCORES[userLevel + 1];
  const isMaxLevel = userLevel === MAX_USER_LEVEL;

  return (
    <Card className={styles.card} data-testid="dashboard-profile">
      <CardPreview className={styles.cardPreview}>
        <img src={userAvatar} alt={userName} className={styles.userAvatar} />

        {isLoadingTeamsUser && (
          <div>
            <LineSkeleton style={{ marginTop: "4px", width: "180px" }} />
          </div>
        )}

        {!isLoadingTeamsUser && teamsUser && (
          <Subtitle1 block className={styles.userInfo}>
            {userName}

            {isErrorUser && (
              <Tooltip
                withArrow
                content="Unable to load user profile data."
                relationship="label"
              >
                <ErrorCircle20Regular
                  className={styles.userErrorTooltip}
                  onClick={() => refetchUser()}
                  data-testid="retry-fetch-profile-button"
                />
              </Tooltip>
            )}
          </Subtitle1>
        )}

        {isLoadingUser && (
          <div data-testid="dashboard--profile-skeleton-loader">
            <LineSkeleton style={{ marginTop: "4px", width: "150px" }} />
            <LineSkeleton style={{ marginTop: "4px", width: "100px" }} />
          </div>
        )}

        {isSuccessUser && (
          <div className={styles.profileInfo} data-testid="dashboard--profile">
            <Subtitle2 block>
              {userLevelLabel} (Level {userLevel})
            </Subtitle2>

            <Body1 block>
              Score:
              <Tooltip
                withArrow
                content={
                  isMaxLevel
                    ? "Maximum level reached"
                    : `${
                        userNextLevelScore - userScore
                      } points left to reach next level`
                }
                relationship="description"
              >
                <span className={styles.scorePoints}>
                  {isMaxLevel
                    ? userScore
                    : `${userScore}/${userNextLevelScore}`}
                  <FoodPizzaRegular
                    style={{ position: "relative", top: "2px" }}
                  />
                </span>
              </Tooltip>
            </Body1>
          </div>
        )}
      </CardPreview>
    </Card>
  );
};
