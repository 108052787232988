import * as React from "react";
import { useEffect } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button as FluentUiButton,
  Input,
  makeStyles,
  Field,
  Select,
  Toast,
  ToastTitle,
  ToastBody,
  ToastFooter,
  tokens,
  shorthands,
  Body1,
} from "@fluentui/react-components";
import classNames from "classnames";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAdminContext } from "../context/AdminContext";

import { UserApi, UserRole } from "../../../../../api/UserApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserProfile } from "../../../../DashBoard/hooks/useUserProfile";
import { useStoreContext } from "../../../../../context/StoreContext";
import Button from "../../../../../components/Button";
import { QueryKey } from "../../../../../constants/api";
import { EditUserFormValidationSchema } from "../utils/validationSchema";
import { USERS_ROLES } from "../constants";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  hiddenFields: {
    display: "none",
  },
  formFieldError: {
    "& select": {
      backgroundColor: tokens.colorStatusDangerBackground1,
      ...shorthands.border("1", "solid", tokens.colorStatusDangerBorder1),
    },
  },
  formFieldErrorMessage: {
    color: tokens.colorStatusDangerForeground1,
  },
});

export interface EditUserForm {
  id: number;
  providerUserId: string;
  name: string;
  role: UserRole;
}

interface EditUserDialogProps {}

export const EditUserDialog: React.FC<EditUserDialogProps> = () => {
  const styles = useStyles();
  const { dispatchToast } = useStoreContext();
  const { teamsUser } = useUserProfile();
  const {
    isEditUserDialogOpen,
    setIsEditUserDialogOpen,
    editUser,
    setEditUser,
  } = useAdminContext();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<EditUserForm>({
    resolver: yupResolver(
      EditUserFormValidationSchema
    ) as Resolver<EditUserForm>,
    defaultValues: {
      id: editUser?.id,
      providerUserId: editUser?.providerUserId,
      name: editUser?.name,
      role: editUser?.role,
    },
  });

  const queryClient = useQueryClient();
  const { mutateAsync: updateUser, isPending: isUpdateUserPending } =
    useMutation({
      mutationKey: [QueryKey.UpdateUser],
      mutationFn: UserApi.updateUser,
    });

  const onSubmit: SubmitHandler<EditUserForm> = async (data: EditUserForm) => {
    try {
      const response = await updateUser(data);

      if (response?.id) {
        setIsEditUserDialogOpen(false);
        setEditUser(null);

        queryClient.invalidateQueries({
          queryKey: [QueryKey.GetUsers, { tenantId: teamsUser?.tenantId }],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKey.GetUser, teamsUser?.objectId],
        });

        dispatchToast(
          <Toast>
            <ToastTitle>User role updated successfully!</ToastTitle>
            <ToastBody>
              From now on {response.name} will be an{" "}
              {USERS_ROLES[response.role]}.
            </ToastBody>
          </Toast>,
          {
            intent: "success",
          }
        );
      }
    } catch (exception: unknown) {
      const error = exception as Error;

      if (error.message) {
        console.error(error.message);
        dispatchToast(
          <Toast>
            <ToastTitle>Oops!</ToastTitle>
            <ToastBody>
              Something went wrong while trying to update the user's role.
            </ToastBody>
            <ToastFooter>Please try again later.</ToastFooter>
          </Toast>,
          {
            intent: "error",
          }
        );
      }
    }
  };

  useEffect(() => {
    if (editUser) {
      setValue("id", editUser?.id);
      setValue("providerUserId", editUser?.providerUserId);
      setValue("name", editUser?.name);
      setValue("role", editUser?.role);
    }
  }, [editUser, setValue]);

  const handleCloseClick = () => {
    setIsEditUserDialogOpen(false);
    setEditUser(null);
  };

  return (
    <Dialog open={isEditUserDialogOpen}>
      <DialogSurface aria-describedby={undefined}>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="edit-user-dialog">
          <div className={styles.hiddenFields}>
            <input {...register("id")} />
            <input {...register("providerUserId")} />
          </div>
          <DialogBody>
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent className={styles.content}>
              <Field label="Name" required>
                <Input {...register("name")} disabled={true} />
              </Field>

              <Field label="Role" required>
                <Select
                  {...register("role")}
                  className={classNames({
                    [styles.formFieldError]: !!errors?.role,
                  })}
                  onChange={(_, data) =>
                    setValue("role", data.value as UserRole)
                  }
                >
                  {/* TODO: Think how to handle SuperAdmin role, because it's causing the form to fail */}
                  <option value={UserRole.User}>
                    {USERS_ROLES[UserRole.User]}
                  </option>
                  <option value={UserRole.TenantAdmin}>
                    {USERS_ROLES[UserRole.TenantAdmin]}
                  </option>
                </Select>
                {errors?.role && (
                  <Body1 className={styles.formFieldErrorMessage}>
                    {errors?.role?.message}
                  </Body1>
                )}
              </Field>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <FluentUiButton
                  appearance="secondary"
                  onClick={() => handleCloseClick()}
                >
                  Close
                </FluentUiButton>
              </DialogTrigger>
              <Button
                isLoading={isUpdateUserPending}
                type="submit"
                appearance="primary"
              >
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
};
