import { FC } from "react";
import {
  Field,
  Select,
  Switch,
  Title3,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";

import { User, UserApi } from "../../../../../api/UserApi";
import { Alert } from "../../../../../components/Alert";
import { previewDateFormat, previewTimeFormat } from "../utils/helpers";
import Button from "../../../../../components/Button";
import { QueryKey } from "../../../../../constants/api";
import { UpdateUserConfigurationFormValidationSchema } from "../utils/validationSchema";
import {
  DEFAULT_CONFIGURATION_NOTIFICATIONS,
  ConfigurationDateFormat,
  ConfigurationTimeFormat,
  DEFAULT_USER_CONFIGURATION,
} from "../constants";
import { useFeatureFlag } from "../../../../../hooks/useFeatureFlag";
import { FeatureFlag } from "../../../../../api/FeatureFlagApi";

export interface UpdateUserConfigurationForm {
  dateFormat: string;
  timeFormat: string;
  notifications: {
    weeklySummary: boolean;
    weeklyReminder: boolean;
  };
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalL),
  },
  hiddenFields: {
    display: "none",
  },
  visibleFields: {},
  formFieldError: {
    backgroundColor: tokens.colorStatusDangerBackground1,
    ...shorthands.border("1", "solid", tokens.colorStatusDangerBorder1),
  },
  formFieldErrorMessage: {
    color: tokens.colorStatusDangerForeground1,
  },
});

export interface UserConfigurationFormProps {
  user: User;
  onSuccess: () => void;
}

const UserConfigurationForm: FC<UserConfigurationFormProps> = ({
  user,
  onSuccess,
}) => {
  const styles = useStyles();
  const { value: isNotificationsEnabled } = useFeatureFlag(
    FeatureFlag.SettingsNotifications
  );

  const { register, watch, setValue, handleSubmit } =
    useForm<UpdateUserConfigurationForm>({
      resolver: yupResolver(
        UpdateUserConfigurationFormValidationSchema
      ) as Resolver<UpdateUserConfigurationForm>,
      defaultValues: {
        dateFormat:
          user?.configurations?.settings?.dateFormat ??
          DEFAULT_USER_CONFIGURATION.dateFormat,
        timeFormat:
          user?.configurations?.settings?.timeFormat ??
          DEFAULT_USER_CONFIGURATION.timeFormat,
        notifications: {
          weeklySummary:
            user?.configurations?.settings?.notifications?.weeklySummary ??
            DEFAULT_USER_CONFIGURATION.notifications.weeklySummary,
          weeklyReminder:
            user?.configurations?.settings?.notifications?.weeklyReminder ??
            DEFAULT_USER_CONFIGURATION.notifications.weeklyReminder,
        },
      },
    });

  const values = watch();

  const {
    mutateAsync: updateUserConfiguration,
    isPending: isUpdateUserConfigurationPending,
    isError,
    error,
  } = useMutation({
    mutationKey: [QueryKey.UpdateUserConfiguration],
    mutationFn: UserApi.updateUserConfiguration,
  });

  const onSubmit: SubmitHandler<UpdateUserConfigurationForm> = async (
    data: UpdateUserConfigurationForm
  ) => {
    try {
      const response = await updateUserConfiguration({
        providerUserId: user.providerUserId,
        ...data,
      });

      if (response?.id) {
        onSuccess();
      }
    } catch (exception: unknown) {
      const error = exception as Error;

      if (error.message) {
        console.error(error.message);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.form}
      data-testid="settings--config-form"
    >
      <Field label="Select preferred date format" required>
        <Select
          {...register("dateFormat")}
          onChange={(_, data) => setValue("dateFormat", data.value)}
          data-testid="settings--config-date-format"
        >
          {Object.values(ConfigurationDateFormat)?.map((dateFormat) => (
            <option key={dateFormat} value={dateFormat}>
              {previewDateFormat(dateFormat)}
            </option>
          ))}
        </Select>
      </Field>

      <Field label="Select preferred time format" required>
        <Select
          {...register("timeFormat")}
          onChange={(_, data) => setValue("timeFormat", data.value)}
          data-testid="settings--config-time-format"
        >
          {Object.values(ConfigurationTimeFormat)?.map((timeFormat) => (
            <option key={timeFormat} value={timeFormat}>
              {previewTimeFormat(timeFormat)}
            </option>
          ))}
        </Select>
      </Field>

      {isNotificationsEnabled && <Title3>Notifications</Title3>}

      <div
        className={
          isNotificationsEnabled ? styles.visibleFields : styles.hiddenFields
        }
      >
        <Field label="Weekly Summary">
          <Switch
            {...register("notifications.weeklySummary")}
            checked={values.notifications.weeklySummary}
            label={`Send me a recap for the activity of the team every week`}
            labelPosition="after"
            data-testid="settings--config-notifications-weekly-summary"
            onChange={(event) =>
              setValue("notifications.weeklySummary", event.target.checked)
            }
          />
        </Field>

        <Field label="Weekly Reminder">
          <Switch
            {...register("notifications.weeklyReminder")}
            checked={values.notifications.weeklyReminder}
            label={`Send me a weekly reminder to spread some joy by sending pizza to my team`}
            labelPosition="after"
            data-testid="settings--config-notifications-weekly-reminder"
            onChange={(event) =>
              setValue("notifications.weeklyReminder", event.target.checked)
            }
          />
        </Field>
      </div>

      {isError && error && (
        <Alert
          intent="error"
          title="Oh no, something went wrong!"
          message="We couldn't update the configurations. Please try again later."
        />
      )}

      <Button
        isLoading={isUpdateUserConfigurationPending}
        appearance="primary"
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
};

export default UserConfigurationForm;
