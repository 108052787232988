import { FC, useEffect } from "react";
import {
  Field,
  Input,
  Rating,
  Switch,
  Textarea,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";

import { User } from "../../../../../api/UserApi";
import { Alert } from "../../../../../components/Alert";
import {
  FeedbackApi,
  UserFeedback,
  UserFeedbackStatus,
} from "../../../../../api/FeedbackApi";
import Button from "../../../../../components/Button";
import { QueryKey } from "../../../../../constants/api";
import { SendUserFeedbackFormValidationSchema } from "../utils/validationSchema";

export interface SendUserFeedbackForm {
  id: number | null;
  providerUserId: string | null;
  email: string | null;
  message: string;
  endorsement: boolean;
  score: number;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalL),
  },
  hiddenFields: {
    display: "none",
  },
  formFieldError: {
    backgroundColor: tokens.colorStatusDangerBackground1,
    ...shorthands.border("1", "solid", tokens.colorStatusDangerBorder1),
  },
  formFieldErrorMessage: {
    color: tokens.colorStatusDangerForeground1,
  },
});

export interface UserFeedbackFormProps {
  user?: User | null | undefined;
  userFeedback?: UserFeedback | null | undefined;
  onSuccess: () => void;
}

const UserFeedbackForm: FC<UserFeedbackFormProps> = ({
  user,
  userFeedback,
  onSuccess,
}) => {
  const styles = useStyles();

  const { register, watch, setValue, handleSubmit, reset } =
    useForm<SendUserFeedbackForm>({
      resolver: yupResolver(
        SendUserFeedbackFormValidationSchema
      ) as Resolver<SendUserFeedbackForm>,
      defaultValues: {
        id: userFeedback?.id ?? null,
        providerUserId: user?.providerUserId ?? null,
        email: "",
        message: "",
        endorsement: userFeedback?.endorsement ?? false,
        score: userFeedback?.score ?? 3,
      },
    });

  useEffect(() => {
    if (user) {
      setValue("providerUserId", user?.providerUserId ?? null);
    }
  }, [user, setValue]);

  const values = watch();

  const {
    mutateAsync: sendUserFeedback,
    isPending: isSendUserFeedbackPending,
    isError,
    error,
  } = useMutation({
    mutationKey: [QueryKey.SendUserFeedback],
    mutationFn: FeedbackApi.createUserFeedback,
  });

  const {
    mutateAsync: updateUserFeedback,
    isPending: isUpdateUserFeedbackPending,
    isError: isErrorOnUpdateFeedback,
    error: errorOnUpdateFeedback,
  } = useMutation({
    mutationKey: [QueryKey.UpdateUserFeedback],
    mutationFn: FeedbackApi.updateUserFeedback,
  });

  const onSubmit: SubmitHandler<SendUserFeedbackForm> = async (
    data: SendUserFeedbackForm
  ) => {
    try {
      let response;

      if (userFeedback?.id) {
        response = await updateUserFeedback({
          ...data,
          id: userFeedback.id,
          status: UserFeedbackStatus.Collected,
        });
      } else {
        response = await sendUserFeedback(data);
      }

      if (response?.id) {
        onSuccess();
        reset();
      }
    } catch (exception: unknown) {
      const error = exception as Error;

      if (error.message) {
        console.error(error.message);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.form}
      data-testid="user-feedback-form"
    >
      <div className={styles.hiddenFields}>
        <input {...register("id")} />
        <input {...register("providerUserId")} />
      </div>
      <Field label="E-mail">
        <Input
          {...register("email")}
          type="email"
          value={values.email ?? ""}
          placeholder="(only if you want a reply from the developer)"
          data-testid="feedback-form--email"
        />
      </Field>

      <Field label="Message" required>
        <Textarea
          {...register("message")}
          value={values.message}
          placeholder="Let us know what you think about PizzaTime..."
          data-testid="feedback-form--message"
        />
      </Field>

      <Field label="We like pizza as well!">
        <Switch
          {...register("endorsement")}
          checked={values.endorsement}
          label={`Send us an endorsement?`}
          labelPosition="after"
          data-testid="feedback-form--endorsement"
          onChange={(event) => setValue("endorsement", event.target.checked)}
        />
      </Field>

      <Field label="Rate your experience">
        <Rating
          value={values.score}
          max={5}
          color="marigold"
          onChange={(_, data) => setValue("score", data.value)}
        />
      </Field>

      {isError && error && (
        <Alert
          intent="error"
          title="Oh no, something went wrong!"
          message="We couldn't collect your feedback. Please try again later."
        />
      )}

      {isErrorOnUpdateFeedback && errorOnUpdateFeedback && (
        <Alert
          intent="error"
          title="Oh no, something went wrong!"
          message="We couldn't collect your feedback. Please try again later."
        />
      )}

      <Button
        isLoading={isSendUserFeedbackPending || isUpdateUserFeedbackPending}
        appearance="primary"
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
};

export default UserFeedbackForm;
