import {
  Card,
  CardHeader,
  CardPreview,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import React from "react";
import { DailyRecipeImage } from "./DailyRecipe/DailyRecipeImage";
import { DailyRecipeTip } from "./DailyRecipe/DailyRecipeTip";
import { DailyRecipeInfo } from "./DailyRecipe/DailyRecipeInfo";
import { containerBreakpoints } from "../../../utils/breakpoints";

interface UserDailyRecipeProps {}

const useStyles = makeStyles({
  card: {
    alignItems: "normal",
    ...containerBreakpoints.xs({
      flexDirection: "column",
    }),
  },
  cardHeader: {
    flexShrink: "100 !important",
  },
  cardPreview: {
    width: "130px",
    height: "130px",
    ...shorthands.margin(`0 !important`, `0 !important`),
    ...containerBreakpoints.xs({
      display: "flex",
      width: "100%",
      height: "auto",
      aspectRatio: "1 / 1",
    }),
  },
});

export const UserDailyRecipe: React.FC<UserDailyRecipeProps> = () => {
  const styles = useStyles();

  return (
    <Card
      orientation="horizontal"
      className={styles.card}
      data-testid="dashboard-daily-recipe"
    >
      <CardPreview className={styles.cardPreview}>
        <DailyRecipeImage />
      </CardPreview>

      <CardHeader
        className={styles.cardHeader}
        header={<DailyRecipeInfo />}
        description={<DailyRecipeTip />}
        data-testid="dashboard-daily-recipe-info"
      />
    </Card>
  );
};
