import React, { useEffect } from "react";
import {
  Divider,
  LargeTitle,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import { Profile } from "./components/Profile";
import { Activity } from "./components/Activity";
import { UserDailyRecipe } from "./components/UserDailyRecipe";
import { containerBreakpoints } from "../../utils/breakpoints";
import Setup from "../Setup";
import { useStoreContext } from "../../context/StoreContext";
import { DashboardProvider } from "./context/DashboardContext";
import { UserFeedbackSection } from "./components/UserFeedbackSection";
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics";
import { UserProfileDialog } from "../../shared/components/UserProfileDialog/UserProfileDialog";
import { UserProfileDialogProvider } from "../../shared/components/UserProfileDialog/context";

const useStyles = makeStyles({
  root: {
    containerType: "inline-size",
    containerName: "page-container",
  },
  title: {
    ...containerBreakpoints.xs({
      textAlign: "center",
    }),
  },
  layout: {
    display: "flex",
    ...shorthands.margin(tokens.spacingVerticalL, 0),
    ...shorthands.gap(tokens.spacingVerticalL),

    ...containerBreakpoints.s({
      flexWrap: "wrap",
      flexDirection: "column-reverse",
    }),
    ...containerBreakpoints.xs({
      flexWrap: "wrap",
      flexDirection: "column",
    }),
  },
  activity: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    maxWidth: "100%",
    ...shorthands.gap(tokens.spacingVerticalL),

    ...containerBreakpoints.s({
      flexBasis: "100%",
      width: "100%",
      height: "100%",
    }),
    ...containerBreakpoints.xs({
      maxWidth: "100%",
    }),
  },
  profile: {
    flexGrow: 1,

    ...containerBreakpoints.m({
      alignSelf: "start",
    }),
  },
});

export const DashBoard = () => {
  const styles = useStyles();

  const { isTenantConfigured } = useStoreContext();
  const { sendPageView } = useGoogleAnalytics();

  useEffect(() => {
    sendPageView({
      page: "/dashboard",
      title: "Dashboard",
    });
  }, []);

  if (!isTenantConfigured) {
    return <Setup />;
  }

  return (
    <DashboardProvider>
      <UserProfileDialogProvider>
        <div className={styles.root} data-testid="dashboard-page">
          <LargeTitle block className={styles.title}>
            Dashboard
          </LargeTitle>
          <Divider />

          <div className={styles.layout}>
            <div className={styles.activity}>
              <UserFeedbackSection />
              <UserDailyRecipe />
              <Activity />
            </div>
            <div className={styles.profile}>
              <Profile />
            </div>
          </div>
        </div>
        <UserProfileDialog />
      </UserProfileDialogProvider>
    </DashboardProvider>
  );
};
