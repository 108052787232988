import { PaginationConfig } from "../components/Pagination";
import { CreateTenantForm } from "../tabs/Setup/components/SetupTenant";
import { Api } from "./Api";
import { User } from "./UserApi";

export type Tenant = {
  id: number;
  providerTenantId: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

interface GetTenantUsersResponse {
  data: User[];
  pagination: PaginationConfig;
}

export class TenantApi {
  public static API_ENDPOINT = `tenants`;

  public static async getTenant(
    providerTenantId: string | undefined
  ): Promise<Tenant | null> {
    if (!providerTenantId) {
      return null;
    }

    const url = `${TenantApi.API_ENDPOINT}/${providerTenantId}`;
    const response = await Api.get(url);

    return response;
  }

  public static async createTenant(data: CreateTenantForm): Promise<Tenant> {
    const url = `${TenantApi.API_ENDPOINT}/`;
    const response = await Api.post(url, data);

    return response;
  }

  public static async getUsers(
    providerTenantId: string | undefined,
    params: { pagination: PaginationConfig }
  ): Promise<GetTenantUsersResponse> {
    const queryParams = {
      ...params.pagination,
    };

    const url = `${TenantApi.API_ENDPOINT}/${providerTenantId}/users`;
    const response = await Api.get(url, queryParams);

    return response;
  }
}
