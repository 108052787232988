import React, { useEffect } from "react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { TabPanel } from "../TabPanel";
import { VersionCards } from "./components/VersionCards";
import { useGoogleAnalytics } from "../../../../hooks/useGoogleAnalytics";

const useStyles = makeStyles({
  root: {
    display: "flex",
    ...shorthands.padding(tokens.spacingVerticalL),
  },
});

export const Changelog = () => {
  const styles = useStyles();

  const { sendPageView } = useGoogleAnalytics();

  useEffect(() => {
    sendPageView({
      page: "/settings/changelog",
      title: "Settings - Changelog",
    });
  }, []);

  return (
    <TabPanel title="Changelog">
      <div className={styles.root} data-testid="settings--changelog">
        <VersionCards />
      </div>
    </TabPanel>
  );
};
