import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogTrigger,
  Button,
} from "@fluentui/react-components";
import { UserFeedback } from "../../../../api/FeedbackApi";
import UserFeedbackForm from "../../../Settings/components/Feedback/components/UserFeedbackForm";
import { Dismiss24Regular } from "@fluentui/react-icons";

interface DialogFeedbackProps {
  userFeedback: UserFeedback;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const DialogFeedback: React.FC<DialogFeedbackProps> = ({
  userFeedback,
  isOpen,
  onClose,
  onSuccess,
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody data-testid="dashboard--feedback-dialog">
          <DialogTitle
            action={
              <DialogTrigger action="close">
                <Button
                  appearance="subtle"
                  aria-label="close"
                  icon={<Dismiss24Regular />}
                  onClick={onClose}
                  data-testid="dashboard--feedback-dialog--close"
                />
              </DialogTrigger>
            }
          >
            Send Feedback
          </DialogTitle>
          <DialogContent>
            <UserFeedbackForm
              userFeedback={userFeedback}
              onSuccess={onSuccess}
            />
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
