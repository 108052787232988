import { PaginationConfig } from "../components/Pagination";
import { DEFAULT_PAGE_SIZE } from "../constants/global";
import { Api } from "./Api";

export enum ActivityType {
  Endorsement = "endorsement",
}

// TODO: Move to shared folder maybe
export enum EndorsementType {
  Neutral = "neutral",
  Sender = "sender",
  Recipient = "recipient",
}

export type UserActivity = {
  id: number;
  activityType: ActivityType;
  userId: number;
  providerUserId: string;
  userName: string;
  type: EndorsementType;
  targetUserId: number;
  targetProviderUserId: string;
  targetUserName: string;
  score: number;
  date: Date | string;
};

interface GetUserActivityParams {
  providerUserId?: string | undefined;
  filters: {
    fromDate: Date;
    toDate: Date;
  };
  pagination: PaginationConfig;
}

export interface GetUserActivityResponse {
  data: UserActivity[];
  pagination: PaginationConfig;
}

export class EndorsementApi {
  public static API_ENDPOINT = `endorsements`;

  /** @deprecated in favor of getChatActivity */
  public static async getUserActivity(
    userId: string | undefined,
    params: GetUserActivityParams
  ): Promise<GetUserActivityResponse> {
    if (!userId) {
      return {
        data: [],
        pagination: {
          page: 1,
          limit: DEFAULT_PAGE_SIZE,
          total: 0,
        },
      };
    }

    const url = `${EndorsementApi.API_ENDPOINT}/activity/${userId}`;
    const queryParams = {
      ...params.filters,
      ...params.pagination,
    };

    const response = await Api.get(url, queryParams);

    return response;
  }

  public static async getChatActivity(
    chatId: string | null | undefined,
    params: GetUserActivityParams
  ): Promise<GetUserActivityResponse> {
    if (!chatId) {
      return {
        data: [],
        pagination: {
          page: 1,
          limit: DEFAULT_PAGE_SIZE,
          total: 0,
        },
      };
    }

    const url = `${EndorsementApi.API_ENDPOINT}/activity/chat/${chatId}`;
    const queryParams = {
      providerUserId: params.providerUserId,
      ...params.filters,
      ...params.pagination,
    };

    if (!queryParams.providerUserId) {
      delete queryParams.providerUserId;
    }

    const response = await Api.get(url, queryParams);

    return response;
  }
}
