import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../../constants/api";
import {
  EndorsementApi,
  GetUserActivityResponse,
} from "../../api/EndorsementApi";
import { PaginationConfig } from "../../components/Pagination";

export interface GetActivityDataInput {
  activityFilterPeriod: [Date, Date];
  pagination: PaginationConfig;
  providerChatId?: string | null;
  providerUserId?: string;
}

interface GetActivityDataHook {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  data: GetUserActivityResponse | undefined;
  refetch: () => void;
}

export const useGetActivityData = ({
  providerChatId,
  providerUserId,
  activityFilterPeriod,
  pagination,
}: GetActivityDataInput): GetActivityDataHook => {
  const getChatActivity = {
    queryKey: [
      QueryKey.GetChatActivity,
      providerChatId,
      providerUserId,
      activityFilterPeriod,
      pagination.page,
    ],
    queryFn: () =>
      EndorsementApi.getChatActivity(providerChatId, {
        providerUserId,
        filters: {
          fromDate: activityFilterPeriod[0],
          toDate: activityFilterPeriod[1],
        },
        pagination: {
          page: pagination.page,
          limit: pagination.limit,
        },
      }),
    enabled: !!providerChatId,
  };

  const getUserActivity = {
    queryKey: [
      QueryKey.GetUserActivity,
      providerUserId,
      activityFilterPeriod,
      pagination.page,
    ],
    queryFn: () =>
      EndorsementApi.getUserActivity(providerUserId, {
        filters: {
          fromDate: activityFilterPeriod[0],
          toDate: activityFilterPeriod[1],
        },
        pagination: {
          page: pagination.page,
          limit: pagination.limit,
        },
      }),
    enabled: !!providerUserId,
  };

  const queryFn = providerChatId ? getChatActivity : getUserActivity;
  const { isLoading, isError, isSuccess, data, refetch } = useQuery(queryFn);

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
  };
};
